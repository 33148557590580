import LazyLoad from "react-lazy-load";

function Fertilityservice(){
    const menu=[
        {
            img:"photo/fertione.jpg",
            name:"Ovulation Induction",
            info:"Ovulation induction is the response of the ovary to exogenous stimulation by direct or indirect method. ",
        },
        // {
        //     img:"photo/fertitwo.jpg",
        //     name:"Intrauterine insemination (IUI)",
        //     info:"IUI  is a procedure for treating infertility. Sperm that have been washed and concentrated are placed directly in your uterus around the time your ovary releases one or more eggs to be fertilized.",
        // },
        // {
        //     img:"photo/fertithree.jpg",
        //     name:"In vitro fertilization (IVF)",
        //     info:"In vitro fertilisation is a process of fertilisation where an egg is combined with sperm in vitro. The process involves monitoring and stimulating a woman’s ovulatory process, removing an ovum or ova from her ovaries and letting sperm fertilise them in a culture medium in a laboratory.",
        // },
    ]
    return (
        <>
        <div className="section">
        <div className="container">
            <div style={{marginBottom:"40px"}} className="title title-fac">
                <h3>Fertility Services</h3>
            </div>
            <div className="rowfacility">
                {
                    menu.map((elem)=>{
                        const {img, name, info}=elem
                        return (<>
                        <div>
                    <LazyLoad className="lazy">
                    <img className="facilityimg" src={img} alt="" />
                    </LazyLoad>
                    <h6>{name}</h6>
                    <p>{info}</p>
                </div>
                        </>)
                    })
                }
            </div>
        </div>
    </div>
        </>
    )
}
export default Fertilityservice;