import { memo, useContext } from "react"
import LazyLoad from "react-lazy-load"
import newservice from "./Newservicelist";
import { Link } from "react-router-dom";
import { data1 } from "../../App";

function Newcontact({num,numer,scans}){
    
    const setscanname=useContext(data1)
    if(num===numer){
        return (<>
            <div className="sectionss nitrition p-2 mb-0">
                {/* <div className="bodyer"></div> */}
                <div className="containers">
                    <div className="rowsss">
                        <div className="fallersss">
                            {/* <div className="imgeboxss">
                                <LazyLoad className="widthinms">
                                    <img src="photo/ct-scan.png" alt="" />
                                </LazyLoad>
                                <h5>Ct Scan</h5>
                            </div> */}
                            <div className="gridtril">
                            
                            {
                                newservice.map((elem)=>{
                                    const {name, scan}=elem;
                                    {
                                        if(scans===name){
                                            return (<>
                                                <div style={{width:"100%"}}>
                                            
                                <div className="fourgrider">
                                {
                                    scan.map((obj)=>{
                                        const {name,content,img}=obj
                                        {
                                            return (<>
                                            <Link onClick={()=>{
                                                setscanname(name)
                                            }} to="/sacan" className="boxxersss">
                                    <div className="inputerx">
                                        <div className="boxxers">
                                            <img src={img} alt="" />
                                        </div>
                                        <div className="textinscan">
                                        <h5>{name}</h5>
                                        <p>{content}</p>
                                        </div>
                                    </div>
                                </Link>
                                            </>)
                                        }
                                    })
                                    
                                } 
                                </div>
                                </div> </>)
                                        }
                                    }
                                }
                                
                                )
                            }
                            </div>
                        </div>
                        <div className="faller filtersc">
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
}
export default memo(Newcontact)