import { memo } from "react"
import "./Serchbar.css"

function Serchbar(){
    return (<>
    <section>
        <div className="rowfull">
            <div className="col-xl-3a"></div>
        </div>
    </section>
    </>)
}
export default memo(Serchbar)