import { memo } from "react"

function Contactvision(){
    return (<>
        <section className="liver mb-0 containers">
            <div className="biggersmall">
                <div className="numberslink">
                    <h4 style={{lineHeight:"40px"}}>Why V Care Scan Centre & Laboratory?</h4>
                    <h6>V Care Scan Centre & Laboratory Services offers diagnostic services and all types of healthcare services under one roof.</h6>
                    <a href="tel:9035122020" style={{textDecoration:"none"}} className="bookappoint">Book Appointment</a>
                </div>
            </div>
            <div className="bgvision pya-5">
                <div className=" p-3 innnerh1">
                    <h1>Our Mission</h1>
                    <p>To provide world-class diagnosis, healthcare, and treatment for patients at an affordable price. With a commitment to honesty, integrity, and dignity, VCAre Scan Services demonstrates social responsibility towards patient care. We also focus on strengthening our relationships with universities, colleges, other hospitals, agencies, and our community.</p>
                </div>
            </div>
            <div className="bgvision pya-5">
                <div className="p-3 innnerh1">
                    <h1>Our Vision</h1>
                    <p>With a devotion to bringing a revolution to the health sector, V Care Scan Centre & Laboratory Services offers diagnostic services and all types of healthcare services under one roof. In addition, we increase awareness and educate the general public about holistic healthcare and its importance for leading a healthy life.</p>
                </div>
            </div>
        </section>
    </>)
}
export default memo(Contactvision)