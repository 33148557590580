import { memo } from "react"
import LazyLoad from "react-lazy-load"
import { Link } from "react-router-dom"

function Bannerofscan(){
    return(<>
        <LazyLoad>
            <div className="banner endoscopybanners" >
                <div>
                    <h4>Scannings & Tests</h4>
                    <div>
                        <Link to="/">Home</Link>/
                        <Link to="">Scannings & Tests</Link>
                    </div>
                </div>
            </div>
            </LazyLoad>
    </>)
}
export default memo(Bannerofscan)