const newservice = [
  {
    name: "Ct Scan",
    scan: [
      {
        name: "Brain",
        img: "../photo/aa1.jpg",
        imgs: "../photo/bbb2.jpg",
        content: "A CT scan of the brain is a medical imaging...",
        maincontent: (
          <>
            <h5>Brain</h5>
            <p>
              A CT scan of the brain, also known as a cranial CT scan, is a
              valuable medical imaging tool used to visualize the brain's
              internal structures and identify abnormalities or injuries. This
              non-invasive procedure involves taking a series of X-ray images
              from different angles, which are then processed by a computer to
              create detailed cross-sectional images of the brain. The resulting
              images provide physicians with crucial information to diagnose and
              assess a wide range of neurological conditions, such as traumatic
              brain injuries, strokes, brain tumors, infections, and bleeding
              within the brain.
            </p>
            <p>
              One of the significant advantages of a brain CT scan is its speed
              and efficiency. Unlike some other imaging techniques, a CT scan is
              relatively quick, taking just a few minutes to complete. This is
              particularly important in emergency situations where rapid
              diagnosis and treatment decisions can be life-saving.
              Additionally, the procedure is generally well-tolerated by most
              patients and can be performed on individuals of all ages, from
              infants to the elderly.
            </p>
            <p>
              In certain cases, a contrast dye may be administered intravenously
              before the scan to enhance the visibility of blood vessels or
              areas of interest within the brain. This is especially helpful for
              detecting tumors, aneurysms, or areas of inflammation. However,
              some patients may be sensitive to contrast material or have
              conditions that prevent its use, so it's essential to inform the
              medical team about any known allergies or health concerns before
              the procedure.
            </p>
            <p>
              In conclusion, a CT scan of the brain is a vital diagnostic tool
              that plays a crucial role in the evaluation and management of
              neurological disorders. Its ability to provide rapid and detailed
              images allows healthcare professionals to make accurate diagnoses
              and create effective treatment plans. As technology continues to
              advance, CT scanning techniques are likely to become even more
              precise and efficient, further enhancing their value in the field
              of neuroimaging.
            </p>
          </>
        ),
      },
      {
        name: "PNS-Coronal, Axial",
        img: "../photo/sss1.jpg",
        content: "A CT scan of the PNS (Paranasal Sinuses) in...",
        imgs: "../photo/bbb1.jpg",
        maincontent: (
          <>
            <h5>PNS-Coronal, Axial</h5>
            <p>
              As of my last knowledge update in September 2021, there is no
              specific medical term or entity known as "PNS-Coronal" or "Axial"
              in the context of human anatomy or medical conditions. However, I
              can provide information about the coronal and axial planes, which
              are important concepts in medical imaging and anatomy.
            </p>
            <p>
              The coronal plane is one of the three fundamental planes used in
              anatomy to describe the orientation of structures within the human
              body. It is a vertical plane that divides the body into anterior
              (front) and posterior (back) portions. In medical imaging, such as
              CT scans or MRIs, the coronal plane shows cross-sectional images
              from a side view. This allows healthcare professionals to examine
              structures and organs from front to back, helping to visualize
              relationships and abnormalities in three-dimensional space.
            </p>
            <p>
              The axial plane, also known as the transverse plane, is another
              essential plane used in anatomy. It is a horizontal plane that
              divides the body into upper and lower portions. In medical
              imaging, the axial plane provides cross-sectional images from a
              top-down perspective, allowing for assessment of structures from
              head to toe. This view is especially useful for evaluating organs
              and structures that lie horizontally in the body, like the brain,
              heart, and pelvis.
            </p>
            <p>
              Both the coronal and axial planes are crucial in medical
              diagnosis, treatment planning, and surgical procedures. Physicians
              and radiologists use these imaging planes to better understand
              anatomical relationships, identify pathologies, and guide
              interventions.
            </p>
            <p>
              It is important to note that medical terminology and knowledge
              might have advanced beyond my last update, so it is always
              advisable to consult the latest medical literature and experts for
              the most current and accurate information.
            </p>
          </>
        ),
      },
      {
        name: "Thorax",
        img: "../photo/sss3.jpg",
        content: "A CT scan of the thorax, also known as a...",
        imgs: "../photo/bbb3.jpg",
        maincontent: (
          <>
            <h5>Thorax</h5>
            <p>
              A thorax CT scan, also known as a chest CT scan, is a diagnostic
              imaging procedure that uses X-rays to create detailed
              cross-sectional images of the chest area. The thorax includes the
              lungs, heart, major blood vessels, trachea, esophagus, and other
              structures within the chest cavity. This non-invasive imaging
              technique provides valuable information for diagnosing and
              evaluating various medical conditions affecting the chest region.
            </p>
            <p>
              During a thorax CT scan, the patient lies on a movable table that
              slides through a large, ring-shaped machine. X-ray beams are
              directed through the body from different angles, and detectors on
              the opposite side of the machine measure the X-rays that pass
              through the tissues. A computer then processes this data to create
              detailed, three-dimensional images of the chest.
            </p>
            <p>
              Thorax CT scans are commonly used to assess lung conditions such
              as pneumonia, lung nodules, tumors, and infections. They are also
              instrumental in diagnosing and staging lung cancers. Additionally,
              a chest CT can reveal abnormalities in the heart and surrounding
              blood vessels, aiding in the detection of heart diseases,
              aneurysms, and pulmonary embolisms.
            </p>
            <p>
              The procedure may require the use of contrast dye to enhance the
              visibility of certain structures or to highlight blood vessels. In
              some cases, a "contrast-enhanced" or "contrast CT" is performed to
              obtain clearer images of specific areas within the thorax.
            </p>
            <p>
              Thorax CT scans are generally safe and painless, but as with any
              medical imaging involving X-rays, patients are exposed to
              radiation. However, the benefits of accurate diagnosis and
              treatment planning usually outweigh the risks associated with the
              minimal radiation exposure.
            </p>
            <p>
              In summary, thorax CT scans are a crucial diagnostic tool in
              modern medicine, enabling healthcare professionals to assess and
              diagnose a wide range of chest-related conditions with precision
              and efficiency. The information obtained from these scans plays a
              pivotal role in guiding treatment decisions and improving patient
              outcomes.
            </p>
          </>
        ),
      },
      {
        name: "HRCT Thorax",
        img: "../photo/sss4.jpg",
        content: "HRCT (High-Resolution Computed Tomography)...",
        imgs: "../photo/bbb4.jpg",
        maincontent: (
          <>
            <h5>HRCT Thorax</h5>
            <p>
              High-resolution computed tomography (HRCT) of the thorax is an
              advanced imaging technique that provides highly detailed and
              precise cross-sectional images of the chest. It is a specialized
              form of chest CT scan that utilizes thinner slices and a higher
              number of images to produce a more comprehensive view of the lungs
              and other thoracic structures. HRCT is particularly useful in
              diagnosing and evaluating lung diseases and is considered the gold
              standard for assessing various pulmonary conditions.
            </p>
            <p>
              HRCT of the thorax is commonly used to assess interstitial lung
              diseases such as idiopathic pulmonary fibrosis, sarcoidosis, and
              hypersensitivity pneumonitis. It allows for the visualization of
              fine lung structures, including the lung parenchyma, bronchial
              walls, and blood vessels, making it easier to identify subtle
              changes and abnormalities associated with these diseases.
            </p>
            <p>
              Another essential application of HRCT thorax is in the evaluation
              of pulmonary nodules and lung cancers. Its high level of detail
              helps distinguish between benign and malignant nodules, aiding in
              early detection and accurate staging of lung cancers.
              Additionally, HRCT can provide valuable information about the
              extent of the tumor and its relation to surrounding structures,
              which is crucial for surgical planning and determining the most
              appropriate treatment approach.
            </p>
            <p>
              HRCT thorax is also instrumental in assessing diffuse lung
              diseases, such as chronic obstructive pulmonary disease (COPD),
              bronchiectasis, and cystic fibrosis. It allows for the evaluation
              of airway changes, bronchial dilatation, and the presence of mucus
              plugs, providing critical insights into disease progression and
              management.
            </p>
            <p>
              The procedure for HRCT thorax is similar to a regular chest CT
              scan, involving the use of X-rays and a contrast medium in some
              cases. The patient is positioned on a CT table and asked to hold
              their breath briefly while the images are taken. As with any CT
              scan, there is some radiation exposure, but the benefits of
              accurate diagnosis and improved patient care often outweigh the
              risks.
            </p>
            <p>
              In summary, HRCT of the thorax is a highly valuable diagnostic
              tool that allows radiologists and healthcare professionals to
              visualize and evaluate lung and thoracic structures with
              exceptional clarity. Its ability to detect and characterize
              various pulmonary conditions makes it an indispensable tool in the
              diagnosis, treatment planning, and monitoring of lung diseases,
              ultimately contributing to better patient outcomes.
            </p>
          </>
        ),
      },
      {
        name: "Neck",
        img: "../photo/sss5.jpg",
        content: "A CT scan of the neck is a medical imaging...",
        imgs: "../photo/bbb5.jpg",
        maincontent: (
          <>
            <h5>Neck</h5>
            <p>
              A neck CT scan, also known as a cervical CT scan, is a diagnostic
              imaging procedure that provides detailed cross-sectional images of
              the structures within the neck region. It is a non-invasive and
              painless imaging technique that uses X-rays to create
              three-dimensional images of the neck, including the soft tissues,
              bones, blood vessels, and other structures in the area. A neck CT
              scan is commonly used to assess and diagnose various medical
              conditions affecting the neck and nearby structures.
            </p>
            <p>
              The procedure involves the patient lying on a movable table that
              is slowly guided through a large, ring-shaped CT scanner. X-ray
              beams are directed through the neck from multiple angles, and
              detectors on the opposite side of the machine measure the X-rays
              that pass through the tissues. A computer then processes this data
              to create highly detailed images of the neck, which can be viewed
              by radiologists and healthcare professionals.
            </p>
            <p>
              A neck CT scan is often ordered to evaluate neck pain, swollen
              lymph nodes, or masses in the neck area. It is also useful in
              diagnosing and staging cancers of the throat, thyroid gland, or
              other structures in the neck. The scan can provide valuable
              information about the size, location, and characteristics of
              tumors, helping physicians plan appropriate treatments or
              surgeries.
            </p>
            <p>
              Furthermore, a neck CT scan can detect abnormalities in the
              salivary glands, thyroid gland, and parathyroid glands. It is also
              helpful in assessing injuries to the bones and soft tissues of the
              neck, such as fractures, dislocations, or trauma-related injuries.
            </p>
            <p>
              In some cases, a contrast dye may be used during the scan to
              enhance the visibility of certain structures or highlight blood
              vessels. This is often referred to as a "contrast-enhanced" or
              "contrast CT" scan.
            </p>
            <p>
              Overall, a neck CT scan is a valuable imaging tool that aids in
              the accurate diagnosis and evaluation of a wide range of
              conditions affecting the neck region. It allows healthcare
              professionals to identify and understand the underlying causes of
              symptoms and guide appropriate treatment plans, leading to better
              patient care and outcomes.
            </p>
          </>
        ),
      },
      {
        name: "Abdomen & Pelvis",
        img: "../photo/sss6.jpg",
        content: "A CT scan of the abdomen and pelvis is a medical...",
        imgs: "../photo/bbb6.jpg",
        maincontent: (
          <>
            <h5>Abdomen & Pelvis</h5>
            <p>
              The abdomen and pelvis are two anatomical regions in the human
              body that play crucial roles in various bodily functions and house
              many vital organs. The abdomen is the area between the chest and
              pelvis, while the pelvis is the lower part of the trunk that
              connects the spine to the legs. These regions are essential for
              digestion, reproduction, and urinary functions, and they contain
              numerous organs and structures that work together to maintain
              overall health and well-being.
            </p>
            <p>
              In the abdomen, some of the major organs include the stomach,
              liver, gallbladder, spleen, pancreas, small intestine, and large
              intestine. The abdomen also houses the kidneys, adrenal glands,
              and ureters. The digestive system processes food and absorbs
              nutrients in the abdomen, while the urinary system filters waste
              products and maintains fluid balance. Any abnormalities or
              diseases in these organs can lead to various health issues, making
              abdominal imaging crucial in diagnosing conditions such as
              appendicitis, gallstones, kidney stones, and gastrointestinal
              tumors.
            </p>
            <p>
              The pelvis, on the other hand, supports the spine and provides a
              stable foundation for the body while protecting and supporting the
              reproductive and urinary organs. In females, the pelvis houses the
              uterus, ovaries, and fallopian tubes, while in males, it contains
              the prostate gland, seminal vesicles, and part of the vas
              deferens. The pelvis is also home to the bladder, rectum, and
              various muscles and ligaments that aid in pelvic floor support.
              Imaging of the pelvis is essential for diagnosing conditions like
              uterine fibroids, ovarian cysts, prostate enlargement, and pelvic
              fractures.
            </p>
            <p>
              Medical imaging techniques such as CT scans, MRI, and ultrasound
              are commonly used to visualize the abdomen and pelvis, providing
              detailed information about the organs and structures within these
              regions. These imaging modalities help healthcare professionals in
              diagnosing and treating a wide range of medical conditions,
              enabling them to create tailored treatment plans that improve
              patient outcomes.
            </p>
            <p>
              Overall, the abdomen and pelvis are complex regions of the body
              with numerous organs and functions that are vital for digestion,
              reproduction, and waste elimination. Proper imaging and evaluation
              of these regions are crucial in identifying and managing various
              medical conditions, ensuring the overall health and well-being of
              individuals.
            </p>
          </>
        ),
      },
      {
        name: "K.U.B",
        img: "../photo/sss7.jpg",
        content: "A CT scan of the abdomen and pelvis is a medical...",
        imgs: "../photo/bbb7.jpg",
        maincontent: (
          <>
            <h5>K.U.B</h5>
            <p>
              A KUB CT scan, also known as a Kidneys, Ureters, and Bladder CT
              scan, is a medical imaging procedure that provides detailed
              cross-sectional images of the abdomen, specifically focusing on
              the kidneys, ureters, and bladder. It is a non-invasive and
              painless diagnostic tool that uses X-rays and advanced computer
              technology to produce precise images of these urinary system
              structures. A KUB CT scan is commonly used to evaluate and
              diagnose various urinary tract conditions and identify
              abnormalities in the kidneys, ureters, and bladder.
            </p>
            <p>
              During the KUB CT scan, the patient lies on a movable table that
              is gently guided through a large, ring-shaped CT scanner. X-ray
              beams are directed through the abdomen from multiple angles, and
              detectors on the opposite side of the machine measure the X-rays
              that pass through the tissues. A computer then processes this data
              to create detailed images of the kidneys, ureters, and bladder,
              which can be viewed by radiologists and physicians.
            </p>
            <p>
              KUB CT scans are valuable in diagnosing kidney stones, as they can
              accurately visualize the size, location, and number of stones
              within the urinary system. Additionally, they can identify other
              kidney abnormalities, such as cysts or tumors. For the ureters, a
              KUB CT scan can help detect obstructions or narrowings that may
              cause urinary flow problems or infections.
            </p>
            <p>
              Moreover, a KUB CT scan is useful in evaluating the bladder for
              various conditions, including bladder stones, tumors, and urinary
              retention. It can also assist in monitoring the effectiveness of
              treatments or surgeries performed on the urinary system.
            </p>
            <p>
              In some cases, a contrast dye may be used during the KUB CT scan
              to enhance the visibility of certain structures or highlight
              abnormalities. This is often referred to as a "contrast-enhanced"
              or "contrast CT" scan.
            </p>
            <p>
              Overall, a KUB CT scan is a valuable imaging tool that allows
              healthcare professionals to assess the health and function of the
              kidneys, ureters, and bladder accurately. It aids in the diagnosis
              of various urinary system conditions, leading to better treatment
              planning and improved patient outcomes.
            </p>
          </>
        ),
      },
      {
        name: "Cervical Spine",
        img: "../photo/sss8.jpg",
        content:
          "A CT scan of the cervical spine is a medical imaging procedure...",
        imgs: "../photo/bbb8.jpg",
        maincontent: (
          <>
            <h5>Cervical Spine</h5>
            <p>
              A cervical spine CT scan is a diagnostic imaging procedure that
              provides detailed cross-sectional images of the cervical spine,
              which is the portion of the vertebral column located in the neck
              region. It is a non-invasive and painless imaging technique that
              uses X-rays and advanced computer technology to create highly
              detailed images of the bones, joints, and soft tissues of the
              cervical spine. Cervical spine CT scans are commonly used to
              evaluate and diagnose various conditions and injuries affecting
              the neck and surrounding structures.
            </p>
            <p>
              During the CT scan, the patient lies on a movable table that is
              slowly guided through a large, ring-shaped CT scanner. X-ray beams
              are directed through the neck from multiple angles, and detectors
              on the opposite side of the machine measure the X-rays that pass
              through the cervical spine. A computer then processes this data to
              create cross-sectional images that can be reconstructed into
              three-dimensional views of the cervical spine.
            </p>
            <p>
              Cervical spine CT scans are particularly useful in assessing
              injuries such as fractures, dislocations, and spinal cord
              compression resulting from accidents or trauma. The detailed
              images can help healthcare professionals accurately identify the
              extent and location of these injuries, aiding in prompt and
              appropriate treatment.
            </p>
            <p>
              Additionally, cervical spine CT scans are valuable in diagnosing
              degenerative conditions of the spine, such as cervical spondylosis
              or herniated discs. They can also help evaluate spinal stenosis,
              which is the narrowing of the spinal canal, and identify bone
              spurs or other abnormalities that may be causing nerve compression
              and pain.
            </p>
            <p>
              Furthermore, cervical spine CT scans are instrumental in planning
              and guiding surgical procedures involving the cervical spine.
              Surgeons can use the images to precisely assess the anatomy and
              plan the surgical approach, resulting in safer and more effective
              operations.
            </p>
            <p>
              In some cases, a contrast dye may be used during the CT scan to
              enhance the visibility of certain structures, such as blood
              vessels or tumors. This is often referred to as a
              "contrast-enhanced" or "contrast CT" scan.
            </p>
            <p>
              In conclusion, cervical spine CT scans are an essential diagnostic
              tool that allows healthcare professionals to visualize and
              evaluate the bones, joints, and soft tissues of the neck region
              accurately. They play a crucial role in diagnosing injuries and
              degenerative conditions of the cervical spine and help in surgical
              planning for improved patient outcomes.
            </p>
          </>
        ),
      },
      {
        name: "LS Spine",
        img: "../photo/sss9.jpg",
        content: "A CT scan of the lumbar spine, often referred to as a CT...",
        imgs: "../photo/bbb9.jpg",
        maincontent: (
          <>
            <h5>LS Spine</h5>
            <p>
              A lumbar spine CT scan, also known as LS spine CT, is a diagnostic
              imaging procedure that provides detailed cross-sectional images of
              the lower back, specifically focusing on the lumbar spine region.
              It is a non-invasive and painless imaging technique that uses
              X-rays and advanced computer technology to produce precise images
              of the bones, joints, and soft tissues of the lumbar spine. LS
              spine CT scans are commonly used to evaluate and diagnose various
              conditions affecting the lower back and surrounding structures.
            </p>
            <p>
              During the CT scan, the patient lies on a movable table that is
              gently guided through a large, ring-shaped CT scanner. X-ray beams
              are directed through the lower back from multiple angles, and
              detectors on the opposite side of the machine measure the X-rays
              that pass through the lumbar spine. A computer then processes this
              data to create detailed images of the lumbar vertebrae,
              intervertebral discs, and other structures, which can be viewed by
              radiologists and physicians.
            </p>
            <p>
              LS spine CT scans are particularly useful in diagnosing and
              evaluating conditions such as herniated discs, spinal stenosis,
              and degenerative disc disease in the lower back. The detailed
              images help healthcare professionals accurately identify the
              location and extent of these conditions, which is crucial for
              developing effective treatment plans.
            </p>
            <p>
              Additionally, LS spine CT scans are instrumental in assessing
              injuries to the lumbar spine, such as fractures or dislocations
              resulting from accidents or trauma. The imaging provides essential
              information for surgeons to plan and guide surgical interventions
              when necessary. Moreover, LS spine CT scans can help identify bone
              abnormalities, such as tumors or infections, in the lower back.
              They can also assist in evaluating spinal alignment and detecting
              any abnormalities that may be causing nerve compression and pain.
            </p>
            <p>
              In some cases, a contrast dye may be used during the CT scan to
              enhance the visibility of certain structures, such as blood
              vessels or tumors. This is often referred to as a
              "contrast-enhanced" or "contrast CT" scan.
            </p>
            <p>
              In conclusion, LS spine CT scans are a valuable diagnostic tool
              that allows healthcare professionals to visualize and evaluate the
              bones, joints, and soft tissues of the lumbar spine accurately.
              They play a crucial role in diagnosing various conditions
              affecting the lower back and aid in planning treatments and
              surgical interventions when needed, leading to improved patient
              care and outcomes.
            </p>
          </>
        ),
      },
      {
        name: "Dorsal Spine",
        img: "../photo/sss10.jpg",
        content: "A CT scan of the dorsal spine, also known as a CT scan...",
        imgs: "../photo/bbb10.jpg",
        maincontent: (
          <>
            <h5>Dorsal Spine</h5>
            <p>
              The dorsal spine, also known as the thoracic spine, is the middle
              portion of the vertebral column, located between the cervical
              (neck) spine and the lumbar (lower back) spine. It is made up of
              twelve vertebrae, labeled T1 to T12, and is structurally unique
              due to its attachment to the rib cage. The dorsal spine provides
              essential support and protection for the thoracic organs, such as
              the heart and lungs, and plays a vital role in maintaining posture
              and stability.
            </p>
            <p>
              Each vertebra of the dorsal spine has a characteristic shape and
              structure. The dorsal vertebrae have long, slender spinous
              processes and articulate with the ribs through their
              costovertebral joints, forming part of the thoracic cage. This
              connection with the ribs limits the flexibility of the thoracic
              spine compared to the cervical and lumbar regions, but it provides
              increased stability and protection for the vital organs in the
              chest cavity.
            </p>
            <p>
              The dorsal spine is responsible for allowing limited forward and
              backward bending, as well as some rotational movement. However,
              its main function is to support the upper body weight and
              facilitate movements of the rib cage during breathing. The
              vertebrae of the dorsal spine are interconnected by strong
              ligaments and muscles that help maintain the structural integrity
              of the thoracic region.
            </p>
            <p>
              Various medical conditions can affect the dorsal spine, including
              degenerative disc disease, herniated discs, scoliosis, and
              compression fractures. Diagnostic imaging techniques such as
              X-rays, CT scans, and MRIs are used to visualize the dorsal
              spine's structures and identify any abnormalities or pathologies.
            </p>
            <p>
              Treatment for dorsal spine conditions depends on the specific
              diagnosis and severity of the condition. Conservative treatments,
              such as physical therapy, pain management, and exercises to
              improve posture, are often the first-line approach. In more severe
              cases or when conservative treatments fail to provide relief,
              surgical interventions may be considered.
            </p>
            <p>
              Overall, the dorsal spine is a critical part of the vertebral
              column, providing support and protection for vital organs and
              enabling essential movements of the chest and upper body.
              Understanding its structure and function is crucial for diagnosing
              and managing various spine-related conditions, ultimately
              contributing to better patient care and outcomes.
            </p>
          </>
        ),
      },
      {
        name: "Temporal Bone HRCT",
        img: "../photo/sss11.jpg",
        content:
          "A CT scan of the temporal bone, often referred to as Temporal...",
        imgs: "../photo/bbb11.jpg",
        maincontent: (
          <>
            <h5>Temporal Bone HRCT</h5>
            <p>
              Temporal bone HRCT (High-Resolution Computed Tomography) is a
              specialized imaging technique that provides detailed and precise
              cross-sectional images of the temporal bones, which are located on
              the sides of the skull, near the ears. It is a non-invasive and
              painless diagnostic tool that uses X-rays and advanced computer
              technology to produce highly detailed images of the bony
              structures, soft tissues, and inner ear structures of the temporal
              bones.
            </p>
            <p>
              Temporal bone HRCT is commonly used to assess and diagnose various
              conditions affecting the ears, including hearing loss, vertigo,
              tinnitus, and ear infections. The high resolution of the images
              allows healthcare professionals to visualize the smallest
              anatomical structures within the temporal bones, providing
              valuable information for accurate diagnosis and treatment
              planning.
            </p>
            <p>
              During the HRCT scan, the patient lies on a movable table that is
              guided through a large, ring-shaped CT scanner. X-ray beams are
              directed through the head from multiple angles, and detectors on
              the opposite side of the machine measure the X-rays that pass
              through the temporal bones. A computer then processes this data to
              create detailed cross-sectional images that can be reconstructed
              into three-dimensional views.
            </p>
            <p>
              Temporal bone HRCT is particularly useful in assessing conditions
              such as cholesteatoma, a non-cancerous cyst that can develop in
              the middle ear, as well as identifying abnormalities in the
              mastoid bone, a part of the temporal bone. It can also detect
              fractures, tumors, and other pathologies affecting the temporal
              bone region.
            </p>
            <p>
              Furthermore, temporal bone HRCT is instrumental in evaluating the
              structures of the inner ear, including the cochlea and vestibular
              system, which are essential for hearing and balance. These images
              can be critical in diagnosing inner ear disorders such as
              Meniere's disease and labyrinthitis.
            </p>
            <p>
              The high level of detail provided by temporal bone HRCT allows for
              improved visualization and characterization of abnormalities
              within the temporal bone region, enabling healthcare professionals
              to offer more precise and effective treatments for patients with
              ear-related conditions.
            </p>
          </>
        ),
      },
      {
        name: "Maxillofacial Region",
        img: "../photo/sss12.jpg",
        content: "A CT scan of the maxillofacial region, often referred to...",
        imgs: "../photo/bbb12.jpg",
        maincontent: (
          <>
            <h5>Maxillofacial Region</h5>
            <p>
              The maxillofacial region is the anatomical area that includes the
              upper jaw (maxilla) and the face (facial bones). A CT scan of the
              maxillofacial region, also known as a maxillofacial CT scan, is a
              specialized imaging technique that provides detailed
              cross-sectional images of these structures. It is a non-invasive
              and painless diagnostic tool that uses X-rays and advanced
              computer technology to produce highly detailed images of the
              bones, soft tissues, and sinuses in the maxillofacial region.
            </p>
            <p>
              Maxillofacial CT scans are commonly used in dentistry, oral and
              maxillofacial surgery, and otolaryngology (ear, nose, and throat)
              practices. They are helpful in diagnosing and planning treatments
              for various conditions and injuries affecting the maxillofacial
              area.
            </p>
            <p>
              During the CT scan, the patient lies on a movable table that is
              guided through a large, ring-shaped CT scanner. X-ray beams are
              directed through the head and face from multiple angles, and
              detectors on the opposite side of the machine measure the X-rays
              that pass through the maxillofacial region. A computer then
              processes this data to create detailed cross-sectional images that
              can be reconstructed into three-dimensional views.
            </p>
            <p>
              Maxillofacial CT scans are particularly useful in assessing facial
              fractures resulting from accidents or trauma. The detailed images
              help healthcare professionals accurately identify the location and
              extent of these fractures, aiding in prompt and appropriate
              treatment planning.
            </p>
            <p>
              Additionally, maxillofacial CT scans can help diagnose and
              evaluate various dental conditions, such as impacted teeth, dental
              abscesses, and tumors of the jaw. They are also useful in
              assessing abnormalities of the sinuses, such as sinusitis or nasal
              polyps.
            </p>
            <p>
              Furthermore, maxillofacial CT scans can aid in the planning and
              evaluation of dental implants, orthognathic surgeries (jaw
              surgery), and other maxillofacial procedures. The high level of
              detail provided by these scans allows for precise measurements and
              assessments, leading to better treatment outcomes.
            </p>
            <p>
              In conclusion, a maxillofacial CT scan is a valuable diagnostic
              tool that allows healthcare professionals to visualize and
              evaluate the bones and soft tissues of the upper jaw and face
              accurately. It plays a crucial role in diagnosing facial
              fractures, dental conditions, and sinus abnormalities, and it is
              instrumental in planning and guiding various maxillofacial
              procedures. The detailed information obtained from maxillofacial
              CT scans enables improved patient care and outcomes in dental and
              maxillofacial surgeries and treatments.
            </p>
          </>
        ),
      },
      {
        name: "Joint",
        img: "../photo/sss13.jpg",
        content: "I'm sorry, but your question seems to be incomplete or...",
        imgs: "../photo/bbb13.jpg",
        maincontent: (
          <>
            <h5>Joint</h5>
            <p>
              In CT scans, joints are areas where two or more bones come
              together and articulate with each other. A CT scan of a joint is a
              diagnostic imaging procedure that provides detailed
              cross-sectional images of the bones, cartilage, ligaments, and
              surrounding soft tissues in the joint. It is a valuable tool for
              assessing joint-related conditions and injuries, as it allows
              healthcare professionals to visualize the joint structures with
              exceptional clarity.
            </p>
            <p>
              During a joint CT scan, the patient lies on a movable table that
              is guided through a large, ring-shaped CT scanner. X-ray beams are
              directed through the body from multiple angles, and detectors on
              the opposite side of the machine measure the X-rays that pass
              through the joint area. A computer then processes this data to
              create detailed images of the joint, which can be viewed by
              radiologists and physicians.
            </p>
            <p>
              Joint CT scans are commonly used to evaluate various joint
              conditions, such as arthritis, fractures, and joint degeneration.
              They can help identify cartilage loss, bone spurs, and other
              changes associated with arthritis, which is valuable in
              determining the extent of joint damage and guiding treatment
              decisions.
            </p>
            <p>
              Furthermore, joint CT scans are instrumental in assessing joint
              injuries resulting from accidents or trauma. The detailed images
              can help healthcare professionals accurately identify the location
              and severity of fractures or dislocations, which is crucial for
              surgical planning and appropriate treatment.
            </p>
            <p>
              In addition to bone and cartilage assessment, joint CT scans can
              visualize soft tissues like ligaments and tendons. This capability
              is beneficial in diagnosing ligamentous injuries, such as anterior
              cruciate ligament (ACL) tears in the knee, or rotator cuff tears
              in the shoulder.
            </p>
            <p>
              Moreover, joint CT scans can be used in image-guided procedures,
              such as joint injections or aspirations, where medications or
              fluids are introduced into the joint space for therapeutic or
              diagnostic purposes.
            </p>
            <p>
              In conclusion, joint CT scans are a valuable diagnostic tool that
              allows healthcare professionals to assess and diagnose various
              joint-related conditions and injuries accurately. The detailed
              images provided by CT scans enable precise evaluation of the
              bones, cartilage, ligaments, and soft tissues within the joint,
              contributing to improved treatment planning and patient care.
            </p>
          </>
        ),
      },
      {
        name: "Extremities",
        img: "../photo/ssss14.jpg",
        content: "A CT scan of the extremities is a medical imaging...",
        imgs: "../photo/bbb14.jpg",
        maincontent: (
          <>
            <h5>Extremities</h5>
            <p>
              The term "extremities" refers to the limbs of the human body,
              including the arms (upper extremities) and the legs (lower
              extremities). Extremities are vital for mobility, balance, and
              various activities of daily living. Medical imaging techniques,
              such as X-rays, CT scans, and MRI, are commonly used to evaluate
              and diagnose conditions affecting the extremities.
            </p>
            <p>
              Extremity X-rays are the most common imaging modality used to
              assess bone and joint conditions. X-rays provide detailed images
              of bones, helping healthcare professionals diagnose fractures,
              dislocations, arthritis, and other bone-related abnormalities.
              They are particularly useful in evaluating trauma-related injuries
              to the extremities, guiding treatment decisions and assessing the
              healing progress.
            </p>
            <p>
              CT scans of the extremities are more detailed than X-rays and
              provide cross-sectional images of bones and soft tissues. CT scans
              are useful for assessing complex fractures, joint injuries, and
              soft tissue conditions. They are often employed in preoperative
              planning for complex fractures or tumors involving the
              extremities.
            </p>
            <p>
              MRI scans are excellent for evaluating soft tissues in the
              extremities, such as muscles, tendons, ligaments, and nerves. MRI
              is valuable for diagnosing conditions like ligament tears, muscle
              strains, and nerve impingement. Additionally, MRI can be helpful
              in diagnosing and monitoring conditions like rheumatoid arthritis
              and osteoarthritis, which may affect multiple joints in the
              extremities.
            </p>
            <p>
              Imaging of the extremities is essential in sports medicine and
              orthopedics, where injuries to the arms and legs are common. It
              allows healthcare professionals to accurately diagnose and treat
              these injuries, facilitating a faster and more effective recovery
              for athletes and patients.
            </p>
            <p>
              Overall, medical imaging of the extremities is an essential tool
              for diagnosing and treating a wide range of conditions affecting
              the arms and legs. It plays a crucial role in orthopedics, sports
              medicine, and general healthcare, helping to restore function and
              mobility to patients and enabling them to maintain an active and
              healthy lifestyle.
            </p>
          </>
        ),
      },
      {
        name: "3D CT",
        img: "../photo/sss15.jpg",
        content: "A 3D CT scan, also known as 3D CT imaging or volume...",
        imgs: "../photo/bbb15.jpg",
        maincontent: (
          <>
            <h5>3D CT</h5>
            <p>
              A 3D CT (Computed Tomography) scan is a specialized imaging
              technique that uses X-rays to create three-dimensional images of
              the body's internal structures. It is a non-invasive and painless
              diagnostic tool that provides detailed and realistic
              representations of organs, bones, blood vessels, and other
              anatomical features. 3D CT scans are particularly useful in
              complex cases where traditional 2D images might not provide
              sufficient information for accurate diagnosis and treatment
              planning.
            </p>
            <p>
              During a 3D CT scan, the patient lies on a movable table that is
              guided through a large, ring-shaped CT scanner. X-ray beams are
              directed through the body from multiple angles, and detectors on
              the opposite side of the machine measure the X-rays that pass
              through the tissues. A computer then processes this data and
              reconstructs it into three-dimensional images that can be viewed
              from different perspectives and manipulated in various ways.
            </p>
            <p>
              The 3D CT images offer a more comprehensive view of the anatomy,
              enabling healthcare professionals to visualize structures in
              detail, such as the size, shape, and spatial relationships of
              organs or the extent and location of abnormalities like tumors or
              fractures. This level of information is particularly valuable in
              surgical planning, where surgeons can better understand complex
              anatomical relationships and potential challenges during
              procedures.
            </p>
            <p>
              In summary, 3D CT scans are an advanced imaging tool that provides
              detailed and realistic three-dimensional images of the internal
              structures of the body. They offer valuable insights for diagnosis
              and treatment planning, especially in complex cases, and can
              assist surgeons in navigating challenging procedures. The ability
              to view the anatomy in three dimensions enhances the precision of
              medical assessments and interventions, ultimately contributing to
              improved patient outcomes.
            </p>
          </>
        ),
      },
      {
        name: "Limited CT",
        img: "../photo/sss16.jpg",
        content: "A limited CT refers to a computed tomography (CT)...",
        imgs: "../photo/bbb16.jpg",
        maincontent: (
          <>
            <h5>Limited CT</h5>
            <p>
              A limited CT scan, also known as a targeted or focused CT scan, is
              a specialized imaging technique that involves obtaining images of
              a specific area or region of the body. Unlike a full-body CT scan,
              which captures images of the entire body, a limited CT scan is
              performed to focus solely on a particular body part or organ of
              interest. This approach minimizes radiation exposure and allows
              for a more efficient and targeted evaluation of specific medical
              concerns.
            </p>
            <p>
              During a limited CT scan, the patient lies on a movable table that
              is guided through a large, ring-shaped CT scanner. X-ray beams are
              directed through the body from various angles, and detectors on
              the opposite side of the machine measure the X-rays that pass
              through the targeted area. The data is then processed by a
              computer to create detailed images of the specific region being
              examined.
            </p>
            <p>
              Limited CT scans are commonly used for various medical purposes.
              For instance, they can be used to evaluate specific organs, such
              as the lungs, liver, kidneys, or brain, when there are symptoms or
              concerns related to those areas. Additionally, limited CT scans
              are employed in preoperative planning to assess a particular
              surgical area in detail, helping surgeons understand the anatomy
              better and optimize their approach.
            </p>
            <p>
              One of the advantages of limited CT scans is the reduced amount of
              time it takes to acquire images. This means the procedure is often
              quicker, resulting in less time spent inside the CT scanner.
              Moreover, the focused nature of the scan minimizes radiation
              exposure to other parts of the body, which is especially
              beneficial for patients who may be sensitive to radiation or
              require multiple scans over time.
            </p>
            <p>
              In conclusion, a limited CT scan is a targeted imaging technique
              used to focus specifically on a particular body part or organ of
              interest. It allows for a more efficient evaluation of specific
              medical concerns, reduces radiation exposure, and aids in
              preoperative planning. This type of CT scan is valuable in
              providing precise information for accurate diagnosis and optimized
              treatment plans.
            </p>
          </>
        ),
      },
    ],
  },
  {
    name: "Obstetric Ultrasound",
    scan: [
      {
        name: "Early Obstetric Scan",
        img: "../photo/sss17.jpg",
        content: "An Early Obstetric Scan, commonly known as an early...",
        imgs: "../photo/bbb17.jpg",
        maincontent: (
          <>
            <h5>Early Obstetric Scan</h5>
            <p>
              An Early Obstetric Scan, commonly known as an early pregnancy
              ultrasound, is a medical procedure performed during the early
              stages of pregnancy. Typically conducted between 6 to 12 weeks of
              gestation, this scan plays a crucial role in assessing the
              viability of the pregnancy, confirming the presence of a
              gestational sac, and providing essential information about the
              developing fetus.
            </p>
            <p>
              During the early obstetric scan, a specialized ultrasound machine
              is used to produce real-time images of the reproductive organs and
              the developing embryo. The procedure is usually non-invasive and
              involves placing a transducer on the mother's abdomen or sometimes
              into the vagina for clearer visualization, depending on the stage
              of pregnancy and the medical condition of the mother.
            </p>
            <p>
              The primary objectives of the early obstetric scan are to confirm
              the pregnancy's location within the uterus, ensuring it is not
              ectopic (outside the uterus), which could be a potentially
              life-threatening condition. It also verifies the number of embryos
              present, helping detect multiple pregnancies such as twins or
              triplets. Moreover, the scan measures the embryo's size to
              estimate the gestational age accurately, aiding in predicting the
              due date.
            </p>
            <p>
              Additionally, the early obstetric scan allows the sonographer or
              doctor to check for any early signs of potential complications,
              such as miscarriage or certain chromosomal abnormalities. Though
              it may not detect all abnormalities at this stage, it can help
              identify potential red flags that might necessitate further
              testing or evaluation.
            </p>
            <p>
              In conclusion, an early obstetric scan is a crucial tool in
              monitoring the health and development of both the mother and the
              unborn child during the early stages of pregnancy. It aids in
              confirming the pregnancy's location, detecting any possible
              complications, determining the gestational age, and verifying the
              number of embryos. This valuable information helps healthcare
              providers provide appropriate care, support, and necessary
              interventions throughout the pregnancy to ensure a healthy outcome
              for both the mother and the baby.
            </p>
          </>
        ),
      },
      {
        name: "Dating & Viability Scan",
        img: "../photo/sss18.jpg",
        content: "A Dating & Viability Scan is a specialized ultrasound...",
        imgs: "../photo/bbb18.jpg",
        maincontent: (
          <>
            <h5>Dating & Viability Scan</h5>
            <p>
              A Dating & Viability Scan is a specialized ultrasound examination
              conducted during the early stages of pregnancy, typically between
              6 to 9 weeks of gestation. This scan serves two main purposes:
              determining the accurate gestational age of the embryo and
              assessing its viability.
            </p>
            <p>
              During the Dating aspect of the scan, the sonographer or doctor
              measures the size of the gestational sac and the embryo to
              estimate the exact gestational age. This information helps
              establish a precise due date for the pregnancy, which is crucial
              for proper prenatal care and monitoring throughout the pregnancy.
            </p>
            <p>
              The Viability aspect of the scan focuses on assessing the embryo's
              viability and confirming a heartbeat. Detecting a fetal heartbeat
              at this stage is a positive sign that the pregnancy is progressing
              well. However, if no heartbeat is observed, it may indicate a
              potential miscarriage or other complications, and additional
              follow-up may be required. This information helps guide medical
              decisions and provides essential emotional support to the
              expectant parents.
            </p>
            <p>
              Overall, the Dating & Viability Scan is a crucial early assessment
              to ensure the pregnancy is progressing as expected and to provide
              accurate information about the gestational age, leading to
              appropriate prenatal care and support for the well-being of both
              the mother and the developing embryo.
            </p>
          </>
        ),
      },
      {
        name: "Nuchal Translucency Scan",
        img: "../photo/sss19.jpg",
        content: "The Nuchal Translucency (NT) Scan is a specialized...",
        imgs: "../photo/bbb19.jpg",
        maincontent: (
          <>
            <h5>Nuchal Translucency Scan</h5>
            <p>
              The Nuchal Translucency (NT) Scan is a specialized prenatal
              screening test conducted between 11 to 14 weeks of gestation. It
              is used to assess the risk of chromosomal abnormalities,
              particularly Down syndrome (Trisomy 21) and other genetic
              conditions, in the developing fetus. The scan measures the
              thickness of the fluid-filled space at the back of the baby's
              neck, known as the nuchal translucency, through an ultrasound
              examination.
            </p>
            <p>
              During the NT scan, a sonographer or doctor will use an ultrasound
              machine to obtain images of the baby and measure the nuchal
              translucency. The presence of an increased nuchal translucency may
              indicate a higher risk of certain chromosomal abnormalities or
              congenital heart defects. It's essential to note that the NT scan
              is a screening test and not a diagnostic test. If the results
              indicate a higher risk, further diagnostic testing, such as
              chorionic villus sampling (CVS) or amniocentesis, may be
              recommended to confirm the presence of a chromosomal abnormality.
            </p>
            <p>
              The NT scan is usually combined with a blood test, known as the
              first-trimester combined screening test. This blood test measures
              specific proteins and hormones in the mother's blood, providing
              additional information to assess the risk more accurately. The
              combination of the NT scan and blood test increases the overall
              accuracy of detecting chromosomal abnormalities, improving the
              reliability of the screening process.
            </p>
            <p>
              It's important to remember that the NT scan is optional, and its
              purpose is to offer expectant parents more information about their
              baby's health and potential risks. Some parents may choose to
              undergo the NT scan to prepare for any potential outcomes, while
              others may decide not to have the test. The decision to have the
              NT scan or any other prenatal screening test should be made based
              on informed consent and discussions with healthcare providers,
              considering individual preferences and circumstances.
            </p>
          </>
        ),
      },
      {
        name: "Anomaly Scan",
        img: "../photo/sss20.jpg",
        content: "The Anomaly Scan, also known as the second-trimester...",
        imgs: "../photo/bbb20.jpg",
        maincontent: (
          <>
            <h5>Anomaly Scan</h5>
            <p>
              The Anomaly Scan, also known as the second-trimester ultrasound or
              Level 2 ultrasound, is a comprehensive prenatal screening test
              performed between 18 to 22 weeks of gestation. The primary purpose
              of this scan is to thoroughly examine the developing fetus for any
              structural abnormalities or anomalies. It is one of the most
              important ultrasound examinations during pregnancy, providing
              crucial information about the baby's health and development.
            </p>
            <p>
              During the Anomaly Scan, a trained sonographer or doctor uses an
              ultrasound machine to produce detailed images of the baby's
              organs, limbs, and other body structures. The scan includes a
              systematic assessment of the brain, spine, heart, kidneys, limbs,
              and other vital organs. This examination helps identify any
              physical or functional abnormalities that may be present.
              Additionally, the scan measures the amniotic fluid volume and the
              position of the placenta.
            </p>
            <p>
              The Anomaly Scan is a non-invasive procedure and is considered
              safe for both the mother and the fetus. It does not involve any
              radiation and is usually performed externally on the mother's
              abdomen. The scan provides valuable information to the expectant
              parents, allowing them to prepare emotionally, make informed
              decisions about their pregnancy, and receive appropriate medical
              care if necessary.
            </p>
            <p>
              It's important to understand that while the Anomaly Scan is highly
              effective in detecting many structural abnormalities, it is not a
              guarantee of a healthy baby. Some conditions may not be detectable
              through ultrasound or may only manifest later in pregnancy or
              after birth. In cases where an anomaly is suspected during the
              scan, further diagnostic testing may be recommended, such as
              amniocentesis or MRI, to confirm the diagnosis and guide
              management.
            </p>
            <p>
              In conclusion, the Anomaly Scan is a critical prenatal screening
              test that provides a detailed assessment of the baby's anatomy and
              helps identify any structural abnormalities. This information is
              vital for ensuring appropriate medical care and support for the
              baby and expectant parents, as well as assisting in planning for
              any potential medical interventions or preparations needed after
              birth. The scan also offers peace of mind to parents when no
              anomalies are found, although it's essential to remember that no
              prenatal screening test can guarantee a completely risk-free
              pregnancy.
            </p>
          </>
        ),
      },
      {
        name: "Pregnanacy with Fetal Echo",
        img: "../photo/sss21.jpg",
        content: "Pregnancy with Fetal Echo refers to a specialized...",
        imgs: "../photo/bbb21.jpg",
        maincontent: (
          <>
            <h5>Pregnanacy with Fetal Echo</h5>
            <p>
              Pregnancy with Fetal Echo refers to a specialized ultrasound
              examination that focuses on evaluating the fetal heart during
              pregnancy. This procedure is typically conducted between 18 to 24
              weeks of gestation and is known as a Fetal Echocardiogram or Fetal
              Echo. The objective of this test is to assess the structure and
              function of the baby's heart and detect any potential cardiac
              abnormalities.
            </p>
            <p>
              During the Fetal Echo, a highly trained sonographer or pediatric
              cardiologist uses an advanced ultrasound machine to obtain
              detailed images of the fetal heart. This allows them to examine
              the heart chambers, valves, blood flow patterns, and other cardiac
              structures. By assessing the heart's function and structure, the
              Fetal Echo can identify congenital heart defects or other cardiac
              abnormalities that might require medical attention or intervention
              after birth.
            </p>
            <p>
              Pregnancy with Fetal Echo is particularly recommended for
              expectant mothers who have certain risk factors, such as a family
              history of congenital heart disease or previous pregnancies with
              heart abnormalities. Additionally, it may be advised if an anomaly
              is suspected during the routine anatomy scan or if the mother has
              specific medical conditions that increase the risk of cardiac
              issues in the baby.
            </p>
            <p>
              In conclusion, Pregnancy with Fetal Echo involves a specialized
              ultrasound examination aimed at evaluating the fetal heart's
              structure and function during pregnancy. The Fetal Echo helps
              identify congenital heart defects or other cardiac abnormalities,
              enabling healthcare providers to provide appropriate medical care
              and support to ensure the best possible outcome for the baby after
              birth. It is a valuable tool for early detection and management of
              cardiac issues, offering expectant parents important information
              about their baby's heart health and allowing for better
              preparation and planning.
            </p>
          </>
        ),
      },
      {
        name: "Interval Growth Scan",
        img: "../photo/sss22.jpg",
        content: "An Interval Growth Scan, also known as a Growth...",
        imgs: "../photo/bbb22.jpg",
        maincontent: (
          <>
            <h5>Interval Growth Scan</h5>
            <p>
              An Interval Growth Scan, also known as a Growth Ultrasound, is a
              prenatal examination performed during the second half of
              pregnancy, typically between 24 to 40 weeks of gestation. The
              primary objective of this scan is to monitor the growth and
              development of the fetus, ensuring it is growing appropriately for
              its gestational age. The scan helps healthcare providers assess
              the baby's well-being and detect any potential growth-related
              issues.
            </p>
            <p>
              During the Interval Growth Scan, a sonographer or doctor uses an
              ultrasound machine to measure various fetal biometric parameters,
              such as head circumference, abdominal circumference, femur length,
              and estimated fetal weight. These measurements are compared with
              standard growth charts specific to the baby's gestational age to
              evaluate if the fetus is growing within the expected range.
              Deviations from the norm may indicate either fetal growth
              restriction or macrosomia (excessive fetal growth), both of which
              may have implications for the baby's health and well-being.
            </p>
            <p>
              The Interval Growth Scan is especially important for high-risk
              pregnancies, such as those involving maternal medical conditions
              like diabetes or hypertension, previous history of fetal growth
              issues, or multiple pregnancies (twins, triplets). In such cases,
              more frequent growth scans may be recommended to closely monitor
              the baby's progress and intervene if necessary.
            </p>
            <p>
              The scan also offers an opportunity to assess the amniotic fluid
              levels and the blood flow in the umbilical cord and other key
              vessels. Abnormal amniotic fluid levels or blood flow patterns may
              indicate potential complications that require further
              investigation and management.
            </p>
            <p>
              By tracking the baby's growth throughout the latter part of
              pregnancy, the Interval Growth Scan plays a crucial role in
              identifying any deviations from the expected growth trajectory.
              Early detection of growth-related issues allows healthcare
              providers to provide appropriate interventions, such as adjusting
              the mother's diet, monitoring more frequently, or planning for a
              timely delivery if required, to optimize the baby's health and
              well-being both before and after birth.
            </p>
          </>
        ),
      },
      {
        name: "Fetal Well Being Scan",
        img: "../photo/sss23.jpg",
        content: "A Fetal Well-Being Scan, also known as a Non-Stress...",
        imgs: "../photo/bbb23.jpg",
        maincontent: (
          <>
            <h5>Fetal Well Being Scan</h5>
            <p>
              A Fetal Well-Being Scan, also known as a Non-Stress Test (NST) or
              Biophysical Profile (BPP), is a prenatal screening test performed
              typically in the third trimester of pregnancy, around 32 to 36
              weeks of gestation. The main purpose of this scan is to assess the
              overall health and well-being of the fetus by evaluating its heart
              rate, movements, and amniotic fluid levels.
            </p>
            <p>
              During the Fetal Well-Being Scan, a sonographer or healthcare
              provider uses ultrasound to measure the amniotic fluid index
              (AFI), which indicates the amount of amniotic fluid surrounding
              the baby. Adequate levels of amniotic fluid are essential for the
              baby's growth and development. Additionally, the scan observes the
              fetal movements, ensuring that the baby is active and responsive.
            </p>
            <p>
              Another critical component of the Fetal Well-Being Scan is the
              assessment of the fetal heart rate. The baby's heart rate is
              monitored over a period of time, either through a Doppler
              ultrasound device or an electronic fetal monitor. This helps
              evaluate the baby's cardiac function and response to movement,
              indicating whether the baby is tolerating the stress of the womb
              environment adequately.
            </p>
            <p>
              The Fetal Well-Being Scan is an important test, especially for
              high-risk pregnancies, maternal medical conditions, or if the
              baby's growth is a concern. Abnormal results in any of the
              parameters may prompt further evaluation or interventions, such as
              more frequent monitoring, additional tests, or considering an
              earlier delivery if there are signs of fetal distress or
              compromised well-being. Ultimately, the Fetal Well-Being Scan
              provides valuable information to healthcare providers, allowing
              them to ensure the baby's health and well-being throughout the
              remainder of the pregnancy and make informed decisions to optimize
              the outcome for both mother and baby.
            </p>
          </>
        ),
      },
      {
        name: "AFI/BPP/3rd Trimester Scan",
        img: "../photo/sss24.jpg",
        content: "The AFI/BPP/3rd Trimester Scan refers to a combination...",
        imgs: "../photo/bbb24.jpg",
        maincontent: (
          <>
            <h5>AFI/BPP/3rd Trimester Scan</h5>
            <p>
              The AFI/BPP/3rd Trimester Scan refers to a combination of two
              prenatal screening tests commonly performed during the third
              trimester of pregnancy, typically between 32 to 36 weeks of
              gestation.
            </p>
            <p>
              AFI stands for Amniotic Fluid Index, and it is a measurement of
              the amount of amniotic fluid surrounding the fetus. Adequate
              amniotic fluid is crucial for the baby's growth and development,
              and the AFI helps assess whether the volume of amniotic fluid is
              within the normal range.
            </p>
            <p>
              BPP stands for Biophysical Profile, which is an evaluation of the
              baby's well-being by assessing five specific parameters: fetal
              movements, fetal heart rate, fetal breathing movements, fetal
              muscle tone, and the AFI. Each parameter is given a score, and the
              cumulative score helps determine the overall fetal well-being.
            </p>
            <p>
              The 3rd Trimester Scan combines both the AFI and BPP to provide a
              comprehensive assessment of the baby's health and development
              during the later stages of pregnancy. This scan helps identify any
              potential issues related to fetal growth, amniotic fluid levels,
              and fetal well-being, allowing healthcare providers to take
              appropriate actions if necessary. In case of abnormal results,
              further monitoring, additional tests, or consideration for an
              early delivery may be recommended to ensure the best possible
              outcome for the baby and the expectant mother.
            </p>
          </>
        ),
      },
      {
        name: "Obstetric Colour Doppler",
        img: "../photo/sss25.jpg",
        content: "Obstetric Color Doppler is a specialized...",
        imgs: "../photo/bbb25.jpg",
        maincontent: (
          <>
            <h5>Obstetric Colour Doppler</h5>
            <p>
              Obstetric Color Doppler is a specialized ultrasound technique used
              during pregnancy to evaluate blood flow patterns in the maternal
              and fetal circulatory systems. This non-invasive procedure helps
              assess the vascular health of the fetus and the placenta, as well
              as any potential complications that may arise during pregnancy.
            </p>
            <p>
              During the Obstetric Color Doppler, a trained sonographer or
              doctor uses an ultrasound machine equipped with color flow mapping
              capabilities. This allows them to visualize and quantify the
              direction, velocity, and patterns of blood flow in real-time. The
              examination includes the assessment of the umbilical cord,
              placenta, and fetal vessels, such as the middle cerebral artery
              and ductus venosus.
            </p>
            <p>
              One of the primary applications of Obstetric Color Doppler is to
              evaluate fetal well-being. By assessing blood flow in the
              umbilical cord and other vessels, healthcare providers can
              identify signs of fetal distress, growth restriction, or other
              placental-related issues. It aids in early detection and
              intervention to improve the outcome for both the mother and the
              baby.
            </p>
            <p>
              Obstetric Color Doppler is particularly valuable in cases of
              high-risk pregnancies, such as those with preeclampsia,
              gestational diabetes, or suspected fetal abnormalities. The scan
              provides additional information to complement standard ultrasound
              evaluations and helps guide medical decision-making and pregnancy
              management.
            </p>
            <p>
              This advanced ultrasound technique also plays a significant role
              in monitoring the success of certain medical interventions, such
              as assessing the effectiveness of treatments for fetal anemia or
              evaluating the response to interventions for twin-to-twin
              transfusion syndrome.
            </p>
            <p>
              In conclusion, Obstetric Color Doppler is an essential tool in
              prenatal care, allowing healthcare providers to assess blood flow
              patterns in the maternal and fetal circulatory systems. By
              evaluating the vascular health of the fetus and placenta, this
              non-invasive technique helps identify potential complications,
              support timely interventions, and ensure optimal care for
              expectant mothers and their unborn babies.
            </p>
          </>
        ),
      },
    ],
  },

  {
    name: "Vascular Colour Doppler",
    scan: [
      {
        name: "Arterial And Venous Doppler",
        img: "../photo/sss26.jpg",
        content: "Arterial and Venous Doppler are two specific types...",
        imgs: "../photo/bbb26.jpg",
        maincontent: (
          <>
            <h5>Arterial And Venous Doppler</h5>
            <p>
              Arterial and Venous Doppler are two specific types of Doppler
              ultrasound used to assess blood flow in different blood vessels
              within the body.
            </p>
            <p>
              Arterial Doppler is a technique used to evaluate blood flow in the
              arteries, which are the blood vessels that carry oxygenated blood
              away from the heart to the rest of the body. This type of Doppler
              ultrasound is commonly performed to assess blood flow in the
              arteries of the arms and legs. It can help detect blockages or
              narrowing of the arteries (atherosclerosis) and assess the
              severity of peripheral artery disease (PAD). Arterial Doppler is
              also used to evaluate blood flow in other areas, such as the
              carotid arteries in the neck to assess the risk of stroke.
            </p>
            <p>
              Venous Doppler, on the other hand, is used to assess blood flow in
              the veins, which are the blood vessels that carry deoxygenated
              blood back to the heart. This type of Doppler ultrasound is
              frequently used to diagnose deep vein thrombosis (DVT), a
              condition where blood clots form in the deep veins, typically in
              the legs. Venous Doppler can help identify the presence, location,
              and extent of blood clots, guiding appropriate treatment and
              management. It is also used to evaluate blood flow in other areas,
              such as the veins in the arms or neck.
            </p>
            <p>
              Both Arterial and Venous Doppler utilize the Doppler effect, which
              detects changes in the frequency of sound waves reflected off
              moving blood cells to calculate blood flow velocity. The
              ultrasound machine processes this information and displays it as a
              color-coded image or waveform, allowing the healthcare provider to
              visualize and analyze the blood flow patterns in real-time.
            </p>
            <p>
              In conclusion, Arterial and Venous Doppler are two specialized
              Doppler ultrasound techniques used to evaluate blood flow in the
              arteries and veins, respectively. These non-invasive procedures
              provide valuable information to diagnose and manage various
              vascular conditions, such as arterial blockages, peripheral artery
              disease, deep vein thrombosis, and assess the risk of stroke or
              other vascular complications. By utilizing the Doppler effect,
              these ultrasound techniques offer real-time visualization and
              quantitative analysis of blood flow patterns, aiding in the early
              detection and appropriate treatment of vascular disorders.
            </p>
          </>
        ),
      },
      {
        name: "Upper And Lower Limb",
        img: "../photo/sss27.jpg",
        content: "The upper and lower limbs refer to the extremities...",
        imgs: "../photo/bbb27.jpg",
        maincontent: (
          <>
            <h5>Upper And Lower Limb</h5>
            <p>
              The upper and lower limbs refer to the extremities of the human
              body. The upper limbs consist of the arms, including the
              shoulders, upper arms (humerus), forearms (radius and ulna),
              wrists, hands, and fingers. These limbs play a vital role in
              performing various tasks, including grasping, manipulating
              objects, and facilitating movement and balance.
            </p>
            <p>
              On the other hand, the lower limbs encompass the legs, including
              the hips, thighs (femurs), lower legs (tibia and fibula), ankles,
              feet, and toes. The lower limbs are essential for supporting the
              body's weight, enabling walking, running, and other forms of
              locomotion. They also contribute to maintaining balance and
              stability during different physical activities.
            </p>
            <p>
              Both upper and lower limbs are critical for everyday life and are
              innervated by nerves and supplied with blood through arteries and
              veins. The musculoskeletal system in these limbs allows for a wide
              range of movements and actions, making them essential components
              of our mobility and interaction with the environment.
            </p>
            <p>
              In summary, the upper and lower limbs are the extremities of the
              human body responsible for various functions and movements. The
              upper limbs enable activities such as grabbing and manipulating
              objects, while the lower limbs are crucial for walking, running,
              and maintaining balance. These limbs, supported by the
              musculoskeletal system and nourished by blood vessels and nerves,
              play a fundamental role in human mobility and daily life
              activities.
            </p>
          </>
        ),
      },
      {
        name: "Renal Doppler Study",
        img: "../photo/sss28.jpg",
        content: "Renal Doppler Study is a specialized ultrasound...",
        imgs: "../photo/bbb28.jpg",
        maincontent: (
          <>
            <h5>Renal Doppler Study</h5>
            <p>
              Renal Doppler Study is a specialized ultrasound examination used
              to assess blood flow in the kidneys. The procedure utilizes
              Doppler ultrasound technology to evaluate the velocity and
              direction of blood flow in the renal arteries and veins, providing
              valuable information about kidney function and potential vascular
              abnormalities.
            </p>
            <p>
              During the Renal Doppler Study, a trained sonographer or doctor
              uses an ultrasound machine equipped with Doppler capabilities to
              visualize the renal arteries and veins. The test measures the
              blood flow velocity in these vessels, which helps identify any
              narrowing or blockages (stenosis) in the renal arteries. Renal
              artery stenosis is a condition that can lead to reduced blood flow
              to the kidneys and may contribute to hypertension (high blood
              pressure) and kidney dysfunction.
            </p>
            <p>
              Additionally, the Renal Doppler Study assesses the resistive index
              (RI) or pulsatility index (PI) of the blood flow. These indices
              provide information about the resistance to blood flow in the
              kidney's small blood vessels (glomeruli) and can aid in diagnosing
              conditions like renal artery stenosis or kidney disease.
            </p>
            <p>
              The Renal Doppler Study is a non-invasive and safe procedure,
              typically performed in cases of suspected renal artery stenosis,
              uncontrolled hypertension, or evaluation of kidney function in
              certain medical conditions. The results of this study help guide
              medical management, assist in planning interventions if necessary,
              and contribute to a better understanding of kidney health and
              blood flow dynamics.
            </p>
          </>
        ),
      },
      {
        name: "Hepato - Portal Doppler Study",
        img: "../photo/sss29.jpg",
        content: "Hepato-Portal Doppler Study is a specialized...",
        imgs: "../photo/bbb29.jpg",
        maincontent: (
          <>
            <h5>Hepato - Portal Doppler Study</h5>
            <p>
              Hepato-Portal Doppler Study is a specialized ultrasound
              examination used to assess blood flow in the liver and the portal
              vein. The procedure utilizes Doppler ultrasound technology to
              evaluate the velocity and direction of blood flow in these
              vessels, providing valuable information about liver function and
              potential vascular abnormalities.
            </p>
            <p>
              During the Hepato-Portal Doppler Study, a trained sonographer or
              doctor uses an ultrasound machine equipped with Doppler
              capabilities to visualize the liver and the portal vein. The test
              measures the blood flow velocity in the portal vein, which is the
              major blood vessel responsible for carrying nutrient-rich blood
              from the gastrointestinal tract to the liver. Changes in the
              portal vein blood flow can indicate conditions like portal
              hypertension, liver cirrhosis, or portal vein thrombosis.
            </p>
            <p>
              The study also assesses the direction of blood flow in the liver's
              intrahepatic blood vessels. This helps detect conditions like
              liver cirrhosis, where there may be changes in the liver's blood
              flow patterns due to scarring and fibrosis.
            </p>
            <p>
              Hepato-Portal Doppler Study is particularly useful in evaluating
              liver function and diagnosing various liver conditions, such as
              cirrhosis, hepatitis, and other liver diseases. It can also help
              identify complications like portal vein thrombosis or portal
              hypertension, which can have significant implications for liver
              health.
            </p>
            <p>
              The procedure is non-invasive and safe, making it a valuable tool
              in the evaluation of liver diseases and the assessment of liver
              and portal vein blood flow dynamics. The results of the study can
              guide medical management, aid in planning interventions, and
              contribute to a better understanding of the patient's liver
              health. Hepato-Portal Doppler Study is typically performed by
              experienced sonographers or radiologists in specialized imaging
              centers or hospitals.
            </p>
          </>
        ),
      },
      {
        name: "Antenatal Doppler Study",
        img: "../photo/sss30.jpg",
        content: "Antenatal Doppler Study, also known as Fetal Doppler...",
        imgs: "../photo/bbb30.jpg",
        maincontent: (
          <>
            <h5>Antenatal Doppler Study</h5>
            <p>
              Antenatal Doppler Study, also known as Fetal Doppler or Maternal
              Doppler, is a specialized ultrasound examination performed during
              pregnancy to assess blood flow in the maternal and fetal
              circulatory systems. This non-invasive procedure uses Doppler
              ultrasound technology to evaluate the direction, velocity, and
              patterns of blood flow in real-time.
            </p>
            <p>
              During the Antenatal Doppler Study, a trained sonographer or
              doctor uses an ultrasound machine equipped with color flow mapping
              capabilities. The examination includes the assessment of blood
              flow in various blood vessels, such as the uterine arteries,
              umbilical cord, and fetal vessels like the middle cerebral artery.
              By monitoring these blood flow patterns, the study can provide
              important information about fetal well-being, placental function,
              and the risk of certain pregnancy complications.
            </p>
            <p>
              One of the primary applications of Antenatal Doppler Study is to
              detect fetal growth restriction. Abnormal blood flow in the
              uterine arteries and umbilical cord can indicate reduced nutrient
              and oxygen supply to the fetus, leading to inadequate growth.
              Early detection of fetal growth restriction allows healthcare
              providers to implement appropriate interventions to improve the
              baby's health and development.
            </p>
            <p>
              Additionally, Antenatal Doppler Study can assess the risk of
              conditions such as preeclampsia, a serious pregnancy complication
              characterized by high blood pressure and organ damage. Abnormal
              blood flow in the uterine arteries and other vessels may serve as
              an early warning sign of preeclampsia, prompting closer monitoring
              and timely management.
            </p>
            <p>
              In conclusion, Antenatal Doppler Study is a valuable tool in
              prenatal care, providing important information about blood flow
              dynamics in both the maternal and fetal circulatory systems. By
              evaluating blood flow patterns, this non-invasive examination aids
              in detecting fetal growth restriction, assessing placental
              function, and identifying potential complications such as
              preeclampsia. The results of the study enable healthcare providers
              to make informed decisions, offer appropriate medical care, and
              support the well-being of both the expectant mother and the unborn
              baby.
            </p>
          </>
        ),
      },
    ],
  },

  {
    name: "General Ultrasound",
    scan: [
      {
        name: "Abdomen & Pelvis",
        img: "../photo/sss31.jpg",
        content: "A CT scan of the brain is a medical imaging...",
      },
      {
        name: "Pelvis",
        img: "../photo/sss32.jpg",
        content: "The pelvis is a complex bony structure located...",
        imgs: "../photo/bbb32.jpg",
        maincontent: (
          <>
            <h5>Pelvis</h5>
            <p>
              The pelvis is a complex bony structure located at the base of the
              spine and serves as the foundation for the trunk and lower limbs.
              It is formed by several bones, including the two hip bones (ilium,
              ischium, and pubis), the sacrum, and the coccyx. The pelvis plays
              a crucial role in supporting the weight of the upper body and
              transferring it to the lower limbs, allowing for stability and
              movement.
            </p>
            <p>
              The pelvis is divided into two main regions: the greater (false)
              pelvis and the lesser (true) pelvis. The greater pelvis lies above
              the pelvic brim and accommodates the abdominal organs. The lesser
              pelvis is a narrower and deeper region that houses the pelvic
              organs, including the bladder, reproductive organs (uterus in
              females and prostate in males), and part of the large intestine.
            </p>
            <p>
              The female pelvis differs from the male pelvis in shape and size
              due to its role in childbirth. The female pelvis has a wider
              subpubic angle, a larger pelvic outlet, and a more spacious pelvic
              cavity to facilitate the passage of the baby during childbirth.
            </p>
            <p>
              In addition to supporting body weight and protecting vital organs,
              the pelvis serves as the attachment site for various muscles,
              including those responsible for movement, stability, and posture.
              It also houses the hip joint, which allows for a wide range of leg
              movements, including walking, running, and jumping.
            </p>
            <p>
              In conclusion, the pelvis is a vital bony structure that forms the
              base of the spine and supports the trunk and lower limbs. Its role
              in weight-bearing, movement, and housing important organs makes it
              a critical component of the human skeletal system. The differences
              between the male and female pelvis highlight the importance of the
              pelvis in childbirth, making it an essential anatomical feature in
              the human body.
            </p>
          </>
        ),
      },
      {
        name: "KUB",
        img: "../photo/sss33.jpg",
        content: "A KUB scan, also known as a Kidney, Ureter, and...",
        imgs: "../photo/bbb33.jpg",
        maincontent: (
          <>
            <h5>KUB</h5>
            <p>
              A KUB scan, also known as a Kidney, Ureter, and Bladder scan, is a
              diagnostic imaging procedure that provides detailed images of the
              abdomen to visualize the kidneys, ureters, and bladder. It is a
              non-invasive and relatively simple examination commonly used to
              assess the urinary system and diagnose various urological
              conditions.
            </p>
            <p>
              During the KUB scan, a series of X-ray images are taken from
              different angles while the patient lies on their back. These
              X-rays allow healthcare providers to visualize the size, shape,
              and position of the kidneys, ureters (the tubes that carry urine
              from the kidneys to the bladder), and the bladder. Additionally,
              the scan can help identify any abnormalities, such as kidney
              stones, urinary tract infections, or other structural issues
              within the urinary system.
            </p>
            <p>
              The KUB scan is widely used for initial evaluations of kidney
              stones, as it can quickly identify the presence and location of
              these calcified deposits. It can also help assess the severity of
              the condition and guide appropriate treatment decisions, such as
              prescribing pain relief medication or recommending surgical
              intervention to remove larger stones.
            </p>
            <p>
              Furthermore, the KUB scan is valuable in diagnosing urinary tract
              infections or abnormalities that may lead to urinary obstruction.
              By detecting any blockages or anatomical irregularities,
              healthcare providers can devise effective treatment plans and
              prevent potential complications.
            </p>
            <p>
              In conclusion, the KUB scan is a non-invasive diagnostic imaging
              procedure used to visualize the kidneys, ureters, and bladder.
              This examination helps identify kidney stones, urinary tract
              infections, and other urological conditions. Its quick and
              effective imaging capabilities make it an essential tool in the
              initial assessment and diagnosis of urinary system-related issues,
              allowing for timely and appropriate medical interventions.
            </p>
          </>
        ),
      },
      {
        name: "Chest",
        img: "../photo/sss34.jpg",
        content: "The chest is a vital anatomical region located...",
        imgs: "../photo/bbb34.jpg",
        maincontent: (
          <>
            <h5>Chest</h5>
            <p>
              The chest is a vital anatomical region located between the neck
              and the abdomen. It houses and protects several essential organs,
              including the heart, lungs, and major blood vessels. The bony
              framework of the chest is formed by the ribs, sternum
              (breastbone), and thoracic vertebrae of the spine. The chest plays
              a crucial role in facilitating breathing, as it contains the
              lungs, which are responsible for exchanging oxygen and carbon
              dioxide with the bloodstream.
            </p>
            <p>
              The heart, a muscular organ, is situated in the center of the
              chest and is responsible for pumping blood throughout the body. It
              is protected by the rib cage and plays a central role in supplying
              oxygen and nutrients to all organs and tissues.
            </p>
            <p>
              The lungs are a pair of spongy, air-filled organs located on
              either side of the chest. They are responsible for taking in
              oxygen from the air we breathe and expelling carbon dioxide, a
              waste product of cellular metabolism. The rib cage and the
              diaphragm, a dome-shaped muscle at the base of the chest,
              facilitate breathing by creating changes in lung volume.
            </p>
            <p>
              The chest also contains various major blood vessels, including the
              aorta, superior and inferior vena cava, and pulmonary arteries and
              veins. These vessels transport oxygenated and deoxygenated blood
              to and from the heart and other parts of the body.
            </p>
            <p>
              In summary, the chest is a vital region of the body that houses
              and protects essential organs such as the heart and lungs. Its
              bony framework provides structural support and protection, while
              its muscles and diaphragm enable breathing. The chest's functions
              are critical for sustaining life by facilitating the exchange of
              oxygen and carbon dioxide and circulating blood to all organs and
              tissues throughout the body.
            </p>
          </>
        ),
      },
      {
        name: "Neuro Sonography",
        img: "../photo/sss35.jpg",
        content: "Neurosonography, also known as transcranial...",
        imgs: "../photo/bbb35.jpg",
        maincontent: (
          <>
            <h5>Neuro Sonography</h5>
            <p>
              Neurosonography, also known as transcranial ultrasound or brain
              ultrasound, is a specialized imaging technique used to assess the
              brain and other neurological structures through ultrasound
              technology. It is a non-invasive and safe procedure commonly
              performed in infants and young children to evaluate brain
              development, detect abnormalities, and monitor neurological
              conditions.
            </p>
            <p>
              During neurosonography, a trained sonographer or doctor uses an
              ultrasound machine equipped with a high-frequency transducer to
              obtain images of the brain through the infant's fontanelles, which
              are the soft spots on the baby's skull. These fontanelles allow
              for the ultrasound waves to penetrate the skull and provide clear
              images of the brain's structure and blood flow.
            </p>
            <p>
              Neurosonography is particularly valuable in assessing brain
              development and detecting conditions such as hydrocephalus, which
              is an abnormal accumulation of cerebrospinal fluid in the brain.
              It can also help identify brain bleeds (intraventricular
              hemorrhage) in premature infants or other brain abnormalities,
              including ventriculomegaly and cysts.
            </p>
            <p>
              In addition to examining the brain, neurosonography can also
              assess the blood flow in the brain's blood vessels using Doppler
              ultrasound. This helps in identifying any vascular abnormalities,
              such as arterial or venous malformations, which may affect the
              brain's blood supply.
            </p>
            <p>
              One of the main advantages of neurosonography is its ability to be
              performed at the bedside, making it readily available in neonatal
              intensive care units (NICUs) and pediatric settings. The procedure
              is non-invasive, does not require sedation, and provides real-time
              images, allowing for immediate assessment and intervention when
              necessary.
            </p>
            <p>
              In conclusion, neurosonography is a valuable imaging technique
              used to assess brain development, detect neurological
              abnormalities, and monitor brain conditions in infants and young
              children. Its non-invasive nature, bedside accessibility, and
              real-time imaging capabilities make it an essential tool in
              neonatal and pediatric care, enabling early detection and timely
              intervention for improved neurological outcomes.
            </p>
          </>
        ),
      },
      {
        name: "Thyroid",
        img: "../photo/sss36.jpg",
        content: "The thyroid is a small, butterfly-shaped gland located...",
        imgs: "../photo/bbb36.jpg",
        maincontent: (
          <>
            <h5>Thyroid</h5>
            <p>
              The thyroid is a small, butterfly-shaped gland located in the
              front of the neck, just below the Adam's apple. It is a crucial
              part of the endocrine system and plays a vital role in regulating
              metabolism, energy production, and various body functions. The
              thyroid gland produces thyroid hormones, primarily
              triiodothyronine (T3) and thyroxine (T4), which are released into
              the bloodstream and affect nearly every cell in the body.
            </p>
            <p>
              Thyroid hormones control the rate at which the body uses energy
              and help maintain body temperature, heart rate, and digestion.
              They also play a critical role in brain development, bone health,
              and maintaining healthy skin, hair, and nails.
            </p>
            <p>
              The thyroid gland is regulated by the pituitary gland in the
              brain, which produces thyroid-stimulating hormone (TSH). TSH
              stimulates the thyroid gland to produce and release thyroid
              hormones in response to the body's needs.
            </p>
            <p>
              Thyroid disorders can result from either overactivity
              (hyperthyroidism) or underactivity (hypothyroidism) of the thyroid
              gland. Hyperthyroidism leads to excessive production of thyroid
              hormones, causing symptoms like weight loss, rapid heartbeat,
              anxiety, and tremors. Hypothyroidism, on the other hand, results
              in insufficient production of thyroid hormones, leading to
              symptoms such as weight gain, fatigue, cold intolerance, and dry
              skin.
            </p>
            <p>
              In conclusion, the thyroid is a small but vital gland that
              regulates metabolism and energy production by producing and
              releasing thyroid hormones. These hormones play a crucial role in
              various body functions, affecting nearly every cell in the body.
              Imbalances in thyroid hormone levels can lead to thyroid
              disorders, with hyperthyroidism causing excessive hormone
              production and hypothyroidism resulting in insufficient hormone
              production. Proper diagnosis and management of thyroid disorders
              are essential for maintaining overall health and well-being.
            </p>
          </>
        ),
      },
      {
        name: "Breast-Rt/Lt/Both",
        img: "../photo/sss37.jpg",
        content:
          "The breasts, also known as mammary glands, are an essential...",
        imgs: "../photo/bbb37.jpg",
        maincontent: (
          <>
            <h5>Breast-Rt/Lt/Both</h5>
            <p>
              The breasts, also known as mammary glands, are an essential part
              of the female reproductive system. They are located on the chest,
              above the ribcage and below the collarbone. Breast tissue consists
              of a network of lobules and ducts responsible for producing and
              transporting milk during lactation.
            </p>
            <p>
              Breast-Rt/Lt/Both refers to the examination of either the right
              breast (Breast-Rt), the left breast (Breast-Lt), or both breasts
              (Breast-Both) during a clinical breast examination or diagnostic
              imaging, such as mammography or ultrasound.
            </p>
            <p>
              Clinical breast examination involves a physical examination
              performed by a healthcare provider to assess the size, shape, and
              texture of the breasts, as well as to check for any lumps, skin
              changes, or other abnormalities that may warrant further
              investigation. This examination is an essential component of
              breast health screening and early detection of breast cancer.
            </p>
            <p>
              Mammography and ultrasound are imaging modalities used to obtain
              detailed images of the breast tissue. These imaging studies can
              detect early signs of breast cancer, such as suspicious masses or
              microcalcifications, even before they can be felt during a
              physical examination. They are valuable tools for breast cancer
              screening and diagnosis, particularly in women with a higher risk
              of breast cancer due to family history or other risk factors.
            </p>
            <p>
              Breast-Rt/Lt/Both examinations, whether clinical or through
              imaging, are essential for women's health, allowing for early
              detection and treatment of breast conditions and breast cancer.
              Regular breast self-examination, clinical breast examinations by
              healthcare providers, and appropriate screening mammograms are
              recommended as part of routine breast health care for women of all
              ages. Early detection and timely intervention significantly
              improve the prognosis and outcomes of breast-related health
              issues.
            </p>
          </>
        ),
      },
      {
        name: "Scrotum",
        img: "../photo/sss38.jpg",
        content:
          "The scrotum is a pouch-like structure located behind the penis...",
        imgs: "../photo/bbb38.jpg",
        maincontent: (
          <>
            <h5>Scrotum</h5>
            <p>
              The scrotum is a pouch-like structure located behind the penis,
              between the legs. It is part of the male reproductive system and
              serves as a protective enclosure for the testicles. The scrotum is
              made up of skin and smooth muscles that allow it to contract or
              relax, regulating the temperature of the testicles for optimal
              sperm production.
            </p>
            <p>
              The primary function of the scrotum is to house the testicles,
              also known as testes. The testicles are responsible for producing
              sperm and the male sex hormone testosterone. The scrotum's
              position outside the body cavity allows the testicles to be
              maintained at a slightly lower temperature than the rest of the
              body, which is crucial for the production of healthy sperm.
            </p>
            <p>
              The scrotum is an adjustable structure. When it is cold, the
              smooth muscles contract, pulling the testicles closer to the body
              to keep them warm. Conversely, in warm conditions, the muscles
              relax, allowing the testicles to hang lower and cool down. This
              ability to adjust the testicular position is essential for
              maintaining sperm health and fertility.
            </p>
            <p>
              The scrotum is sensitive and can be affected by various
              conditions, including inflammation, infections, and trauma. Common
              conditions that can affect the scrotum include epididymitis
              (inflammation of the epididymis), orchitis (inflammation of the
              testicle), and varicocele (enlarged veins in the scrotum). These
              conditions may cause pain, swelling, or changes in the size and
              shape of the scrotum, requiring medical evaluation and treatment.
            </p>
            <p>
              In conclusion, the scrotum is a crucial part of the male
              reproductive system, providing a protective environment for the
              testicles and facilitating sperm production. Its ability to adjust
              the position of the testicles based on temperature conditions is
              essential for maintaining sperm health and fertility. Regular
              self-examination of the scrotum, along with seeking medical
              attention for any abnormalities or discomfort, is essential for
              maintaining good reproductive health and early detection of any
              potential issues.
            </p>
          </>
        ),
      },
      {
        name: "Transvaginal(TVS)",
        img: "../photo/sss39.jpg",
        content: "Transvaginal ultrasound (TVS) is a specialized imaging...",
        imgs: "../photo/bbb39.jpg",
        maincontent: (
          <>
            <h5>Transvaginal(TVS)</h5>
            <p>
              Transvaginal ultrasound (TVS) is a specialized imaging technique
              used to visualize the pelvic organs and structures in females. It
              involves inserting a small ultrasound probe, called a transducer,
              into the vagina to obtain detailed images of the reproductive
              organs and nearby structures. TVS provides a more accurate and
              close-up view compared to abdominal ultrasound and is commonly
              used in gynecological examinations and during early pregnancy.
            </p>
            <p>
              During a TVS, the patient lies on their back, and the transducer,
              covered with a sterile condom and lubricant, is gently inserted
              into the vagina. The transducer emits sound waves that bounce off
              the pelvic structures, and the returning waves are translated into
              real-time images on a monitor. This allows healthcare providers to
              visualize the uterus, cervix, ovaries, fallopian tubes, and
              surrounding tissues in detail.
            </p>
            <p>
              In gynecology, TVS is often used to assess conditions such as
              abnormal bleeding, pelvic pain, and ovarian cysts. It can help
              identify the presence of uterine fibroids, polyps, and other
              abnormalities within the reproductive organs. TVS is also utilized
              to monitor the development and growth of ovarian follicles during
              fertility treatments or to guide procedures such as ovarian cyst
              aspiration or egg retrieval during in vitro fertilization (IVF).
            </p>
            <p>
              During early pregnancy, TVS is commonly used to confirm pregnancy,
              visualize the gestational sac, and detect the fetal heartbeat. It
              provides valuable information about the pregnancy's location,
              viability, and gestational age, and is essential for assessing the
              risk of ectopic pregnancies or miscarriages.
            </p>
            <p>
              TVS is a safe and well-tolerated procedure. It is typically
              performed with minimal discomfort and without the need for
              anesthesia. The use of TVS has significantly improved
              gynecological diagnosis and early pregnancy evaluation, allowing
              for timely interventions and improved patient care.
            </p>
          </>
        ),
      },
      {
        name: "Transrectal(TRS)",
        img: "../photo/sss40.jpg",
        content: "Transrectal ultrasound (TRS) is a specialized imaging...",
        imgs: "../photo/bbb40.jpg",
        maincontent: (
          <>
            <h5>Transrectal(TRS)</h5>
            <p>
              Transrectal ultrasound (TRS) is a specialized imaging technique
              used to visualize the structures within the pelvis and lower
              abdomen, particularly the prostate gland in males. It involves
              inserting a small ultrasound probe, called a transducer, into the
              rectum to obtain detailed images of the pelvic organs and nearby
              structures. TRS provides a close-up and accurate view of the
              prostate gland and surrounding tissues and is commonly used for
              prostate examinations and certain medical procedures.
            </p>
            <p>
              During a TRS, the patient usually lies on their side with their
              knees bent, and the transducer, covered with a protective sheath
              and lubricant, is gently inserted into the rectum. The transducer
              emits sound waves that bounce off the prostate gland and nearby
              structures, and the returning waves are translated into real-time
              images on a monitor. This allows healthcare providers to visualize
              the size, shape, and condition of the prostate gland and assess
              for any abnormalities.
            </p>
            <p>
              TRS is extensively used in urology to evaluate conditions related
              to the prostate gland, such as benign prostatic hyperplasia (BPH),
              prostatitis (inflammation of the prostate), and prostate cancer.
              It is particularly valuable for assessing the size and consistency
              of the prostate, identifying prostate nodules or tumors, and
              guiding prostate biopsies to obtain tissue samples for cancer
              diagnosis.
            </p>
            <p>
              The TRS procedure is generally well-tolerated by patients and is
              relatively quick. It is considered safe and non-invasive, with
              minimal discomfort. TRS is also commonly used to guide certain
              medical procedures, such as prostate brachytherapy (a type of
              radiation therapy) and prostate cryoablation (a treatment for
              prostate cancer that uses freezing temperatures).
            </p>
            <p>
              In conclusion, transrectal ultrasound (TRS) is a valuable imaging
              technique used to visualize the structures within the pelvis,
              particularly the prostate gland in males. It provides detailed and
              real-time images of the prostate and surrounding tissues, making
              it an essential tool for diagnosing and monitoring prostate
              conditions, including prostate cancer. The TRS procedure is
              well-tolerated, safe, and non-invasive, allowing for accurate
              assessments and guided medical interventions for better patient
              care.
            </p>
          </>
        ),
      },
      {
        name: "Follicular Study",
        img: "../photo/sss41.jpg",
        content: "Follicular study, also known as follicle...",
        imgs: "../photo/bbb41.jpg",
        maincontent: (
          <>
            <h5>Follicular Study</h5>
            <p>
              Follicular study, also known as follicle monitoring or ovulation
              tracking, is a series of ultrasound examinations performed during
              a woman's menstrual cycle to monitor the growth and development of
              ovarian follicles. Ovarian follicles are small fluid-filled sacs
              within the ovaries that contain eggs. The study helps assess the
              woman's reproductive health, predict ovulation, and increase the
              chances of successful conception, especially for couples
              undergoing fertility treatments.
            </p>
            <p>
              During a follicular study, the woman undergoes regular
              transvaginal ultrasounds at specific intervals throughout her
              menstrual cycle. The first ultrasound is usually performed on day
              2 or 3 of the menstrual period to establish the baseline status of
              the ovaries and count the number of resting follicles. Subsequent
              ultrasounds are performed every few days to observe the growth of
              the dominant follicle(s) that will likely release an egg during
              ovulation.
            </p>
            <p>
              The ultrasound scans measure the size of the growing follicles and
              the thickness of the endometrial lining (the inner lining of the
              uterus). The goal is to identify the dominant follicle(s) that are
              likely to release a mature egg and to monitor the endometrial
              lining's growth and preparation for embryo implantation.
            </p>
            <p>
              Follicular study is commonly used in assisted reproductive
              technologies, such as in vitro fertilization (IVF) or intrauterine
              insemination (IUI), to optimize the timing of the fertility
              treatment. By predicting ovulation accurately, healthcare
              providers can schedule the procedures to maximize the chances of
              fertilization and successful pregnancy.
            </p>
            <p>
              In conclusion, follicular study is a valuable tool in fertility
              assessment and treatment planning. It involves regular ultrasound
              examinations to monitor the growth and development of ovarian
              follicles, helping to predict ovulation and increase the chances
              of successful conception. Follicular study is particularly useful
              in assisted reproductive technologies, guiding the timing of
              procedures such as IVF or IUI to optimize the chances of achieving
              pregnancy. By closely monitoring the ovarian and endometrial
              changes, healthcare providers can provide personalized fertility
              care and support couples on their journey towards parenthood.
            </p>
          </>
        ),
      },
    ],
  },

  {
    name: "Prenatal Marker",
    scan: [
      {
        name: "Double Marker",
        img: "../photo/sss42.jpg",
        content: "The Double Marker test is a prenatal...",
        imgs: "../photo/bbb42.jpg",
        maincontent: (
          <>
            <h5>Double Marker</h5>
            <p>
              The Double Marker test is a prenatal screening test performed
              during the first trimester of pregnancy to assess the risk of
              certain chromosomal abnormalities in the developing fetus. It
              combines two important blood markers - human chorionic
              gonadotropin (hCG) and pregnancy-associated plasma protein-A
              (PAPP-A) - to provide information about the risk of Down syndrome
              (Trisomy 21) and Trisomy 18.
            </p>
            <p>
              During the Double Marker test, a blood sample is taken from the
              pregnant woman between 10 to 13 weeks of gestation. The levels of
              hCG and PAPP-A in the blood are measured and compared to the
              expected levels for the specific stage of pregnancy. Abnormal
              levels of these markers may indicate an increased risk of
              chromosomal abnormalities in the fetus.
            </p>
            <p>
              The Double Marker test does not provide a definitive diagnosis of
              chromosomal abnormalities but rather calculates the risk based on
              statistical analysis. If the test results show a
              higher-than-average risk, further diagnostic tests, such as
              chorionic villus sampling (CVS) or amniocentesis, may be
              recommended to confirm the presence of chromosomal abnormalities.
            </p>
            <p>
              The Double Marker test is considered a non-invasive and safe
              screening option, allowing expectant parents to gather important
              information about their baby's health and make informed decisions
              regarding further testing or medical interventions. It is
              typically offered to all pregnant women, especially those with
              advanced maternal age or other risk factors for chromosomal
              abnormalities.
            </p>
            <p>
              In conclusion, the Double Marker test is a prenatal screening test
              that combines two blood markers, hCG and PAPP-A, to assess the
              risk of Down syndrome and Trisomy 18 in the developing fetus. The
              test provides important information about the likelihood of
              chromosomal abnormalities, helping expectant parents make informed
              decisions about their pregnancy. While the Double Marker test is a
              valuable screening tool, any positive or high-risk results require
              confirmation through more invasive diagnostic tests for a
              definitive diagnosis. It is essential to discuss the test results
              and options with a healthcare provider to understand the
              implications and plan appropriate care for the pregnancy.
            </p>
          </>
        ),
      },
      {
        name: "Quadruple Marker",
        img: "../photo/sss43.jpg",
        content: "The Quadruple Marker test is a prenatal...",
        imgs: "../photo/bbb43.jpg",
        maincontent: (
          <>
            <h5>Quadruple Marker</h5>
            <p>
              The Quadruple Marker test is a prenatal screening test performed
              during the second trimester of pregnancy, typically between 15 to
              20 weeks of gestation. It is also known as the Quad Screen or
              Quadruple Screen. This test is designed to assess the risk of
              certain chromosomal abnormalities and neural tube defects in the
              developing fetus.
            </p>
            <p>
              The Quadruple Marker test measures four specific substances in the
              mother's blood: alpha-fetoprotein (AFP), human chorionic
              gonadotropin (hCG), unconjugated estriol (uE3), and inhibin-A.
              These substances are produced by the placenta and the fetus and
              circulate in the mother's bloodstream. Abnormal levels of these
              markers may indicate an increased risk of conditions like Down
              syndrome (Trisomy 21), Trisomy 18, and neural tube defects such as
              spina bifida.
            </p>
            <p>
              AFP, one of the markers measured in the Quadruple Marker test, is
              a protein produced by the fetus and plays a role in the
              development of the fetal nervous system. Abnormal AFP levels may
              indicate an increased risk of neural tube defects, such as spina
              bifida.
            </p>
            <p>
              The Quadruple Marker test is considered a non-invasive and safe
              screening option, providing valuable information about the baby's
              health without posing any risk to the pregnancy. It is usually
              offered to all pregnant women, but particularly to those who may
              be at higher risk for chromosomal abnormalities or neural tube
              defects, such as women with advanced maternal age or a history of
              these conditions in previous pregnancies.
            </p>
            <p>
              It's important to note that the Quadruple Marker test is a
              screening test, not a diagnostic test. Any positive or high-risk
              results require further evaluation through more invasive
              diagnostic tests, such as amniocentesis or chorionic villus
              sampling (CVS), to confirm the presence of the suspected
              abnormalities.
            </p>
            <p>
              In conclusion, the Quadruple Marker test is a prenatal screening
              test that measures four specific substances in the mother's blood
              to assess the risk of chromosomal abnormalities and neural tube
              defects in the developing fetus. The test is non-invasive and
              provides valuable information to expectant parents about the
              health of their baby. However, positive or high-risk results
              should be followed up with more invasive diagnostic tests for a
              definitive diagnosis. It's essential for expectant parents to
              discuss the results with their healthcare provider and consider
              their options for further testing and care during the pregnancy.
            </p>
          </>
        ),
      },
    ],
  },

  {
    name: "Cardiology",
    scan: [
      {
        name: "2D ECHO",
        img: "../photo/sss44.jpg",
        content: "A 2D echocardiogram, commonly known...",
        imgs: "../photo/bbb44.jpg",
        maincontent: (
          <>
            <h5>2D ECHO</h5>
            <p>
              A 2D echocardiogram, commonly known as 2D ECHO or
              echocardiography, is a non-invasive imaging test that uses
              ultrasound technology to create real-time, two-dimensional images
              of the heart's structures and its function. It is a fundamental
              tool in cardiology and helps assess the heart's health, diagnose
              various cardiac conditions, and monitor treatment effectiveness.
            </p>
            <p>
              During a 2D ECHO, a specially trained sonographer or cardiologist
              places a transducer, a small hand-held device, on the patient's
              chest. The transducer emits high-frequency sound waves that bounce
              off the heart's structures, creating detailed images on a monitor.
              The images show the heart's chambers, valves, walls, and major
              blood vessels, providing valuable information about their size,
              shape, and function.
            </p>
            <p>
              The 2D ECHO allows healthcare providers to evaluate the heart's
              pumping function, known as the ejection fraction, which is an
              essential indicator of heart health. It can detect abnormalities
              in heart valve function, such as valve stenosis or regurgitation,
              and identify congenital heart defects or structural abnormalities.
            </p>
            <p>
              In addition to 2D images, the test can be enhanced with Doppler
              ultrasound, which measures blood flow velocity in the heart and
              blood vessels. This Doppler ECHO helps assess blood flow through
              the heart's chambers and valves, identify areas of turbulence, and
              evaluate for any abnormalities, such as leaks or obstructions.
            </p>
            <p>
              2D ECHO is a versatile and safe diagnostic tool that provides
              valuable information to aid in the diagnosis and management of
              various cardiac conditions. It is used to evaluate patients with
              symptoms like chest pain, shortness of breath, or palpitations, as
              well as to monitor patients with known heart conditions, assess
              treatment efficacy, and track disease progression. With its
              ability to offer detailed information about the heart's anatomy
              and function, the 2D ECHO is an essential imaging technique in
              cardiology and contributes to better patient care and outcomes.
            </p>
          </>
        ),
      },
      {
        name: "ECG",
        img: "../photo/sss45.jpg",
        content: "An electrocardiogram (ECG or EKG) is a...",
        imgs: "../photo/bbb45.jpg",
        maincontent: (
          <>
            <h5>ECG</h5>
            <p>
              An electrocardiogram (ECG or EKG) is a common and non-invasive
              diagnostic test used to assess the electrical activity of the
              heart. It provides valuable information about the heart's rhythm
              and function, aiding in the diagnosis and monitoring of various
              heart conditions.
            </p>
            <p>
              During an ECG, small electrodes are attached to the skin on the
              chest, arms, and legs. These electrodes detect the electrical
              signals generated by the heart as it contracts and relaxes. The
              signals are then amplified and recorded on a graph, producing a
              visual representation of the heart's electrical activity.
            </p>
            <p>
              The ECG waveform consists of several waves and intervals that
              correspond to different phases of the cardiac cycle. The P wave
              represents atrial depolarization, the QRS complex indicates
              ventricular depolarization, and the T wave reflects ventricular
              repolarization. By analyzing these waves and intervals, healthcare
              providers can identify abnormalities in the heart's rhythm and
              identify potential cardiac issues.
            </p>
            <p>
              ECG is commonly used to diagnose various heart conditions, such as
              arrhythmias (abnormal heart rhythms), myocardial infarction (heart
              attack), and conduction disorders. It can also help assess the
              effects of certain medications or interventions on the heart's
              electrical activity.
            </p>
            <p>
              The ECG is a quick and painless procedure, typically taking only a
              few minutes to perform. It is often one of the first tests
              administered when a patient presents with symptoms like chest
              pain, palpitations, dizziness, or shortness of breath.
            </p>
            <p>
              In conclusion, an electrocardiogram (ECG) is a valuable diagnostic
              tool used to assess the heart's electrical activity and rhythm. It
              helps identify abnormalities in the heart's function, aiding in
              the diagnosis and management of various heart conditions. The ECG
              is a non-invasive and widely available test, providing quick
              results that assist healthcare providers in making informed
              decisions and offering appropriate treatment to patients with
              heart-related symptoms or conditions.
            </p>
          </>
        ),
      },
    ],
  },
];
export default newservice;
