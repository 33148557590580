import logo from './logo.svg';
import './App.css';
import Home from './component/Home';
import {BrowserRouter, Router, Routes,Route,} from "react-router-dom"
import About from './component/About';
import Healthpackagepage from './component/Health-package';
import GalleryPage from './component/Gallerypage';
import Contact from './component/Contact-us';
import Ultrasound from './component/Ultrasound-Scan';
import TwodEcho from './component/twod-Echo/Twod-Echo';
import Threefour from './component/td-fd-scan/td-fd-scan';
import DigitalXray from './component/digital-x-ray/DigitalXray';
import ECG from './component/ecg/ecg';
import ClinicalLaboratory from './component/clinical-Laboratory/Clinical-Laboratory';
import Endoscopy from './component/endoscopy/Endoscopy';
import Colonoscopy from './component/colonoscopy/Colonoscopy';
import Obstetrics from './component/Obstetrics/Obstetrics';
import Gynaecology from './component/Gynaecology/Gynaecology';
import Fetal from './component/Fetal/Fetal';
import Ourdocter from './component/Our-doctor/Ourdoctor';
import Consultant from './component/Consultant/Consultant';
import ScanningtestBox from './component/Scanningbox/ScanningtestBox';
import { createContext, useState } from 'react';
import Newscan from './component/Newscan/Newscan';
const data=createContext()
const data1=createContext()
function App() {
  const [scaning, setScanning]=useState("Brain")
  const [scantittle, setScannertittle]=useState("Fetal echo")
  return (
    <data.Provider value={scaning}>
      <data1.Provider value={setScanning}>
      <BrowserRouter>
  
  <Routes>
     <Route path='/' element={<Home scantittle={scantittle} setScannertittle={setScannertittle}/>}/> 
     <Route path='/about' element={<About scantittle={scantittle} setScannertittle={setScannertittle}/>}/> 
     <Route path='/healthpack' element={<Healthpackagepage scantittle={scantittle} setScannertittle={setScannertittle}/>}/> 
     <Route path='/gallery' element={<GalleryPage scantittle={scantittle} setScannertittle={setScannertittle}/>}/>
     <Route path='/contact' element={<Contact scantittle={scantittle} setScannertittle={setScannertittle}/>}/>
     <Route path='/ultrasound' element={<Ultrasound scantittle={scantittle} setScannertittle={setScannertittle}/>}/>
     <Route path='/twodecho' element={<TwodEcho scantittle={scantittle} setScannertittle={setScannertittle}/>}/>
     <Route path='/threefour' element={<Threefour scantittle={scantittle} setScannertittle={setScannertittle}/>}/>
     <Route path='/digitalxay' element={<DigitalXray scantittle={scantittle} setScannertittle={setScannertittle}/>}/>
     <Route path='/ecg' element={<ECG scantittle={scantittle} setScannertittle={setScannertittle}/>}/>
     <Route path='/cliniclab' element={<ClinicalLaboratory scantittle={scantittle} setScannertittle={setScannertittle}/>}/>
     <Route path='/endoscopy' element={<Endoscopy scantittle={scantittle} setScannertittle={setScannertittle}/>}/>
     <Route path='/colonoscopy' element={<Colonoscopy scantittle={scantittle} setScannertittle={setScannertittle}/>}/>
     <Route path='/obstetrics' element={<Obstetrics scantittle={scantittle} setScannertittle={setScannertittle}/>}/>
     <Route path='/gynaecology' element={<Gynaecology scantittle={scantittle} setScannertittle={setScannertittle}/>}/>
     <Route path='/fetal' element={<Fetal scantittle={scantittle} setScannertittle={setScannertittle}/>}/>
     <Route path='/ourdoctor' element={<Ourdocter scantittle={scantittle} setScannertittle={setScannertittle}/>}/>
     <Route path='/consultant' element={<Consultant scantittle={scantittle} setScannertittle={setScannertittle}/>}/>
     <Route path='/sacnningtextbox' element={<ScanningtestBox scantittle={scantittle} setScannertittle={setScannertittle}/>}></Route>
     <Route path='/sacan' element={<Newscan scantittle={scantittle} setScannertittle={setScannertittle}/>}></Route>
     {/* <Route path='/*' element={<Home scantittle={scantittle} setScannertittle={setScannertittle}/>}/> */}
  </Routes>
  </BrowserRouter>
      </data1.Provider>
    </data.Provider>
  );
}

export default App;
export {data,data1}
