import LazyLoad from "react-lazy-load";

function Gynaecologyservice(){
    const menu=[
        {
            img:"photo/infertility.jpg",
            name:"Infertility treatment",
            info:"Treatments for infertility can range from medications to embryo implantation through assisted reproductive technology (ART). There are treatments that are specifically for men or for women and some that involve both partners. In 85% to 90% of cases, infertility is treated with conventional medical therapies, such as medication or surgery.",
        },
        {
            img:"photo/yoga.jpg",
            name:"Adenomyosis",
            info:"Adenomyosis is a condition where the cells that usually grow into the uterine lining instead grow outward into the uterus muscle. During a woman’s menstrual cycle, these “trapped” cells become stimulated, which can make menstrual cramps and bleeding more severe than usual.",
        },
        {
            img:"photo/endomeric.jpg",
            name:"Endometriosis",
            info:"During a pelvic exam, your doctor manually feels (palpates) areas in your pelvis for abnormalities, such as cysts on your reproductive organs or scars behind your uterus. Often, it’s not possible to feel small areas of endometriosis unless they’ve caused a cyst to form.",
        },
        {
            img:"photo/yogatwo.jpg",
            name:"Fibroid",
            info:"Fibroid is a kind of non cancerous growths seen in the uterine muscle layers. This is one of the most common conditions which women may have to face in their life. It is mostly seen among women of 30s to 50s. A fibroid can be of different sizes.",
        },
        {
            img:"photo/yogathree.jpg",
            name:"Reproductive Endocrinology",
            info:"It is a surgical sub speciality of the obstetrics and gynecology department, where the physicians get training in reproductive medicine that address hormonal functioning.",
        },
        {
            img:"photo/gen.jpg",
            name:"Genital Warts",
            info:"Genital warts are a common sexually transmitted infection caused by the human papillomavirus (HPV). The characteristic symptom is a small bump on the genitals.",
        },
        {
            img:"photo/abnormal.jpg",
            name:"Abnormal Uterine Bleeding",
            info:"Abnormal uterine bleeding (AUB) is bleeding from the uterus that is longer than usual or that occurs at an irregular time. Bleeding may be heavier or lighter than usual and occur often or randomly. AUB can occur: As spotting or bleeding between your periods. After sex.",
        },
        {
            img:"photo/hiv.jpg",
            name:"HIV/AIDS Women’s Health",
            info:"The human immunodeficiency virus (HIV) is a sexually transmitted infection (STI). HIV can lead to acquired immunodeficiency syndrome (AIDS). One in four people in the United States with HIV is female. In the United States, women are most likely to get HIV from having sex with a man.",
        },
        {
            img:"photo/urinary.jpg",
            name:"Urinary Incontinence & Infections",
            info:"Urinary incontinence may also be caused by an easily treatable medical condition, such as: Urinary tract infection. Infections can irritate your bladder, causing you to have strong urges to urinate and, sometimes, incontinence.",
        },
        {
            img:"photo/tubal.jpg",
            name:"Tubal Blockage (Fallopian Tube Obstruction)",
            info:"Tubal factor infertility occurs when a blockage in the fallopian tubes will not allow the egg and sperm to meet. Tubal factor infertility accounts for about 25-30% of all cases of infertility.",
        },
        {
            img:"photo/ovary.jpg",
            name:"Polycystic Ovary Syndrome (PCOS)",
            info:"Polycystic ovary syndrome (PCOS) is a problem with hormones that happens during the reproductive years. If you have PCOS, you may not have periods very often. Or you may have periods that last many days",
        },
        {
            img:"photo/cervix.jpg",
            name:"Dilation and Curettage (D&C)",
            info:"Dilation and curettage refers to the dilation of the cervix and surgical removal of part of the lining of the uterus and/or contents of the uterus by scraping and scooping.",
        },
    ]
    return (
        <>
        <div className="section">
        <div className="container">
            <div style={{marginBottom:"40px"}} className="title title-fac">
                <h3>Gynecological Treatments</h3>
            </div>
            <div className="rowfacility">
                {
                    menu.map((elem)=>{
                        const {img, name, info}=elem
                        return (<>
                        <div>
                    <LazyLoad className="lazy">
                    <img className="facilityimg" src={img} alt="" />
                    </LazyLoad>
                    <h6>{name}</h6>
                    <p>{info}</p>
                </div>
                        </>)
                    })
                }
            </div>
        </div>
    </div>
        </>
    )
}
export default Gynaecologyservice;