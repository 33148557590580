import React, { useState } from 'react';
function HeaderTop(){
    return (<>
    <div className="headertop">
        <div>
            <div className="header-top-div">
                <a href="">V Care Scan Centre & Laboratory</a>
            </div>
        </div>
        <div className="header-icon">
            <ul>
                <li><a href=""><img className="img" src="photo/phone.png" alt="" />090351 22020</a></li>
                <li><a href=""><img className="img" src="photo/email.png" alt="" />vcarescan@gmail.com</a></li>
                <li><a href=""><img className="img" src="photo/facebook (2).png" alt="" /></a></li>
                <li><a href=""><img className="img" src="photo/twitter (2).png" alt="" /></a></li>
            </ul>
        </div>
    </div>
    </>)
}
export default HeaderTop;