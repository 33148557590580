import { memo } from "react"

function Newsectioninfor(){
    return (<>
    <div style={{marginTop:"-40px",marginBottom:"20px"}} className="rows ueifujbse">
                <div className="titles">
                    <h1>Scannings & Tests</h1>
                </div>
                <div className="titlescon">
                    <p>
                At V Care Scan Centre & Laboratory, we promise to deliver our patients the ultimate medical diagnostics services. This includes delivering the highest quality Imaging, laboratory and comprehensive health check services with respect, courtesy and compassion.</p>
                </div>
            </div>
    </>)
}
export default memo(Newsectioninfor)