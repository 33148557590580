import Header from '../header';
import HeaderTop from '../Header-top';
import MobileMenu from '../Mobile-menu';
import Footer from '../Footer';
import { useState } from 'react';
import ScrollTop from '../Scrolltop';
import Mainscan from '../mainscantext/MainScan';
import Scantestbut from '../mainscantext/Scantestbut';
import Consultantbanner from './Consultantbanner';
import Consultantcon from './Consultantcon';
function Consultant(props){
    const [foot, setFoot]=useState("none");
    const [disnone,setDisnone]=useState("none");
    const [navbar, setNavbar]=useState("flex");
    const [scan, setScan]=useState("none");
    const [scanButton, setScanButton]=useState("block")
        return(
            <>
          <div className='headbody'>
          <HeaderTop/>
          <Header dis={disnone} setDis={setDisnone} na={navbar} setNa={setNavbar}/>
          <Consultantbanner/>
          <Consultantcon/>
          {/* <Sliderp/> */}
          <Footer foot={foot} setFoot={setFoot} scanButton={scanButton} setScanButton={setScanButton}/>
          </div>
          {/* <ScrollTop/> */}
          <ScrollTop/>
          <MobileMenu dis={disnone} setDis={setDisnone} na={navbar} setNa={setNavbar}/>
          <Mainscan scan={scan} setScan={setScan} scantittle={props.scantittle} setScannertittle={props.setScannertittle}/>
          <Scantestbut scan={scan} setScan={setScan}  foot={foot} setFoot={setFoot} scanButton={scanButton} setScanButton={setScanButton}/>
            </>
        )
}
export default Consultant;