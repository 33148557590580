import { useState } from "react";
import { Link } from "react-router-dom";

function Scantestbut(props) {
  const [contro, setControl] = useState("block");
  const [truea, setTruea] = useState(true);
  function changer() {
    props.setScan("block");
  }
  function buttoncotrol() {
    if (truea === true) {
      setControl("block");
      setTruea(!truea);
    } else {
      setControl("block");
      setTruea(!truea);
    }
  }
  const change = {
    display: `${props.foot}`,
  };
  const noner = {
    display: `${contro}`,
  };
  const chan = {
    display: `${props.scanButton}`,
  };
  return (
    <>
      <div className="scanbut" style={change}>
        {/* <div className="scanbuttop" style={change}>
            <p>Please, call our customer service or get an appointment with our doctor.</p>
            <div className="scanbutsec">
                <div className="bookaapp">
                    <button><Link to="/contact">Book Appointment</Link></button>
                </div>
                <div className="cancelscan-1">
                    <img  onClick={buttoncotrol} src="photo/" alt="" />
                    <div style={noner}>
                    <a className="whats" href="https://wa.me/+919035122020?text=Hello, How can I help you??"><img src="photo/whatsapp.png" alt="" /></a>
                    </div>
                    <div style={noner}>
                    <a className="call" href="tel:"><img src="photo/telephone.png" alt="" /></a>
                    </div>
                </div>
            </div>
        </div> */}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <a
            href="tel:9035122020"
            style={{
              paddingTop: "20px",
              paddingBottom: "3px",
              width: "25%",
              backgroundColor: "#0d1c56",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "0px",
              }}
            >
              <img
                style={{ width: "24px" }}
                src="../photo/telephone-call.png"
                alt=""
              />
            </div>
            <h3
              style={{ fontSize: "12px", textAlign: "center", color: "white" }}
            >
              Call
            </h3>
          </a>
          <a
            href="https://wa.me/+919035122020?text=Hello"
            style={{
              paddingTop: "20px",
              paddingBottom: "5px",
              width: "25%",
              backgroundColor: "#b1151a",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "0px",
              }}
            >
              <img
                style={{ width: "24px" }}
                src="../photo/whatsappaa.png"
                alt=""
              />
            </div>
            <h3
              style={{ fontSize: "12px", textAlign: "center", color: "white" }}
            >
              Whatsapp
            </h3>
          </a>
          <a
            href="https://www.google.com/maps/dir//V+Care+Scan+Centre+and+Laboratory+-+Sunkadakatte,+Magadi+Road+Bangalore+Shop+No.+1,+Jai+Maruthi+Convention+Hall+Building+Magadi+Main+Rd,+near+Bank+Of+Baroda,+Muthuraya+Swamy+Extension,+Sunkadakatte+Bengaluru,+Karnataka+560091/@12.9891615,77.5079914,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x3bae3c491b2c96d5:0x48d9047e5f924933"
            style={{
              paddingTop: "20px",
              paddingBottom: "3px",
              width: "25%",
              backgroundColor: "#0d1c56",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "0px",
              }}
            >
              <img
                style={{ width: "24px" }}
                src="../photo/maps-and-flagsaa.png"
                alt=""
              />
            </div>
            <h3
              style={{ fontSize: "12px", textAlign: "center", color: "white" }}
            >
              Map
            </h3>
          </a>
          <a
            href="melto:vcarescan@gmail.com"
            style={{
              paddingTop: "20px",
              paddingBottom: "5px",
              width: "25%",
              backgroundColor: "#b1151a",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "0px",
              }}
            >
              <img
                style={{ width: "24px" }}
                src="../photo/calendaraa.png"
                alt=""
              />
            </div>
            <h3
              style={{ fontSize: "12px", textAlign: "center", color: "white" }}
            >
              Appointment
            </h3>
          </a>
        </div>
      </div>
    </>
  );
}
export default Scantestbut;
