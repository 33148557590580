import { memo, useContext, useEffect, useState } from "react"
import LazyLoad from "react-lazy-load"
import Newinfobox from "./Newinfobox"
import newservice from "../Newscanningsection/Newservicelist"
import { data } from "../../App"

function Pivtures(){
    const scanername=useContext(data)
    const [menus, setMenus]=useState(newservice)
    return(<>
    <div className="innfotter">
        <div className="namerinfo">
            {
                menus.map((elem)=>{
                    const {scan}=elem
                    {
                        return (<>
                        {
                            scan.map((obje)=>{
            
                                const {name,maincontent,imgs}=obje
                                if(name===scanername){
                                {
                                    return(<>
                                    <LazyLoad className="outerinin">
                                        <img src={imgs} alt="" />
                                    </LazyLoad>
                                    {maincontent}
                                    </>)
                                }
                            }
                            })
                        }
                        </>)
                    }
                                })
            }
        </div>
    </div>
    </>)
}
function Infoboxer(){
    return(<>
    <from className="servers indekjnmk">  
                            <input className="inputers" type="text" />
                            <input className="inputers" type="text" />
                            <textarea className="inputerss" type="text" />
                            <div className="subber">
                                <button className="submiter">Submit</button>
                            </div>
                        </from>
    </>)
}
function Scaninfo(){
    return (<>
    <div className="sections">
        <div className="containers">
            <div className="inforo">
                <Pivtures/>
                <Newinfobox/>
            </div>
            <div>
                
            </div>
        </div>
    </div>
    </>)
}
export default Scaninfo