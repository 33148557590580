import { memo, useContext, useState } from "react"
import "../Newscanningsection/Newservicelist"
import newservice from "../Newscanningsection/Newservicelist";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazy-load";
import { data1 } from "../../App";
function Scanner({namer,nums}){
    const setscanname=useContext(data1)
    return (<>
    {
                                newservice.map((elem, num)=>{
                                    const {name, scan}=elem;
                                    if(num===namer){
                                        if(nums===namer){
                                        {
                                            return (<>
                                                <div style={{width:"100%"}}>
                                            
                                <div className="fourgridera alinaliner">
                                {
                                
                                    scan.map((obj)=>{
                                        const {name,content,img,maincontent}=obj
                                        {
                                            return (<>
                                            <Link onClick={()=>{
                                                setscanname(name)
                                            }} to="/sacan" className="boxxersss">
                                    <div className="inputerx">
                                        <div className="boxxers">
                                            <img src={img} alt="" />
                                        </div>
                                        <div className="textinscan">
                                        <h5>{name}</h5>
                                        <p>{content}</p>
                                        </div>
                                    </div>
                                </Link>
                                            </>)
                                        }
                                    
                                    })
                                    
                                } 
                                </div>
                                </div> </>)
                                    }
                                    }
                                }
                                }
                                )
                            }
    </>)
}
function Newinfobox(){
    const [numer, setNumer]=useState(0)
    const [scan, setScan]=useState("Ct Scan")
    const [namer, setNmaer]=useState("Ct Scan")
    console.log(numer);
    const menu=[
        {
            name:"Ct Scan",
            content:"A CT scan, also known as computed tomography scan or CAT scan, is a medical imaging procedure that uses X-rays and advanced computer technology to create detailed cross-sectional images of the body. "
        },
        {
            name:"Obstetric Ultrasound",
            content:"Obstetric ultrasound, also known as prenatal or pregnancy ultrasound, is a medical imaging technique used to visualize and monitor the developing fetus and the mother's reproductive organs during pregnancy."
        },
        {
            name:"Vascular Colour Doppler",
            content:"Vascular color Doppler, also known as color Doppler ultrasound, is a medical imaging technique that combines traditional ultrasound imaging with color mapping to visualize blood flow within blood vessels."
        },
        {
            name:"General Ultrasound",
            content:"General ultrasound, also known as diagnostic ultrasound or sonography, is a non-invasive medical imaging technique that uses high-frequency sound waves to produce real-time images of internal organs, tissues, and structures within the body."
        },
        {
            name:"Prenatal Marker",
            content:"In prenatal care, a prenatal marker, also known as a prenatal screening marker or prenatal test marker, refers to a specific substance or measurement detected during prenatal screening tests."
        },
        {
            name:"Cardiology",
            content:"Cardiology is a branch of medical science that focuses on the study and management of disorders related to the heart and the circulatory system. Medical professionals who specialize in cardiology are called cardiologists. They are trained to diagnose, treat, and prevent various heart conditions, ensuring optimal heart health for their patients."
        },
    ]
        return (<>
            <div style={{paddingTop:"0px"}} className="nitrition p-2 mb-0 alintoper">
                {/* <div className="bodyer"></div> */}
                <div className="containers">
                    <div className="rowsss">
                        <div className="fallersss">
                            {/* <div className="imgeboxss">
                                <LazyLoad className="widthinms">
                                    <img src="photo/ct-scan.png" alt="" />
                                </LazyLoad>
                                <h5>Ct Scan</h5>
                            </div> */}
                            <div className="gridtril">
                            {
                                menu.map((elem,num)=>{
                                    const {name, content}=elem
                                    {
                                            return(<>
                                                <div onClick={()=>{
                                                    setNumer(num)
                                                    setNmaer(name)
                                                }} className="firstboxs lankbigger">
                                        <div className={
                                                num===numer?"innerboxs":"innerboxss"
                                            }>
                                            <div className={
                                                num===numer?"imgebox":"imgeboxs"
                                            }>
                                                <LazyLoad className={
                                                num===numer?"widthinm":"widthinms"
                                            }>
                                                    <img src={
                                                num===numer?"photo/ct-scan.png":"photo/tomography.png"
                                            } alt="" />
                                                </LazyLoad>
                                                <h5>{name}</h5>
                                            </div>
                                            <div className={
                                                num===numer?"namesss":"namessss"
                                            }>
                                                {/* <p>{content}</p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <Scanner namer={numer} nums={num}/>
                                                </>)
                                        
                                    }
                                }
                                )
                            }
                            
                            
                            </div>
                        </div>
                        <div className="faller filtersc">
                        </div>
                    </div>
                </div>
            </div>
        </>)
}
export default Newinfobox