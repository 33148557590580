import Header from '../header';
import HeaderTop from '../Header-top';
import MobileMenu from '../Mobile-menu';
import Footer from '../Footer';
import { useEffect, useState } from 'react';
import ScrollTop from '../Scrolltop';
import Mainscan from '../mainscantext/MainScan';
import Scantestbut from '../mainscantext/Scantestbut';
import Colonoscopybanner from './Colonoscopybanner';
import Colonologycon from './Colonologycon';
function Colonoscopy(props){
    const [foot, setFoot]=useState("none");
    const [disnone,setDisnone]=useState("none");
    const [navbar, setNavbar]=useState("flex");
    const [scan, setScan]=useState("none");
    const [scanButton, setScanButton]=useState("block")
    useEffect(()=>{
      window.scrollTo({top:0,left:0,behavior:"smooth"})
    },[])
        return(
            <>
          <div className='headbody'>
          <HeaderTop/>
          <Header dis={disnone} setDis={setDisnone} na={navbar} setNa={setNavbar}/>
          <Colonoscopybanner/>
          <Colonologycon/>
          {/* <Sliderp/> */}
          <Footer foot={foot} setFoot={setFoot} scanButton={scanButton} setScanButton={setScanButton}/>
          </div>
          {/* <ScrollTop/> */}
          <ScrollTop/>
          <MobileMenu dis={disnone} setDis={setDisnone} na={navbar} setNa={setNavbar}/>
          <Mainscan scan={scan} setScan={setScan} scantittle={props.scantittle} setScannertittle={props.setScannertittle}/>
          <Scantestbut scan={scan} setScan={setScan}  foot={foot} setFoot={setFoot} scanButton={scanButton} setScanButton={setScanButton}/>
            </>
        )
}
export default Colonoscopy;