import LazyLoad from "react-lazy-load";
import Linkbox from "../Linkbox";
function Colonologycon(){
    return (<>
        <div className="section">
            <div className="container width">
                <div className="maincontent">
                    <div className="confirst">
                        <div>
                        <div>
                            <LazyLoad>
                        <img src="photo/faeight.jpg" alt=""/>
                        </LazyLoad>
                        </div>
                        <div className="facilityinfo">
                            <h6>Pregnancy scanning</h6>
                            <p>Pregnancy scanning, also known as obstetric ultrasonography, is a medical imaging technique that uses high-frequency sound waves to create images of a developing fetus inside the uterus of a pregnant woman. It is a non-invasive and safe procedure that is typically performed during prenatal care to monitor the health and growth of the fetus.

During a pregnancy scan, a gel is applied to the woman's abdomen, and a transducer is placed on the skin. The transducer emits sound waves that bounce off the fetus and create echoes, which are detected by the transducer and used to create an image of the fetus on a monitor. The scan can also be done through the vagina in certain situations.</p>
                            <p>Pregnancy scanning can be used to determine the gestational age of the fetus, check for multiple pregnancies, assess the size and position of the fetus, detect any abnormalities, and monitor the development of organs such as the heart, lungs, brain, and kidneys.

There are different types of pregnancy scans, including a dating scan in the first trimester, a nuchal translucency scan to assess the risk of chromosomal abnormalities, an anomaly scan in the second trimester to check for structural abnormalities, and growth scans later in the pregnancy to monitor fetal growth.

It is important to note that pregnancy scanning should only be performed by trained healthcare professionals and only when medically necessary.

</p>
                        
                        </div>
                        </div>
                    </div>
                    <div className="consecond">
                        <div className="innerbox">
                            <div className="innerboxfirst">
                                <div className="facititle">
                                    <h6>Facilities</h6>
                                </div>
                                <Linkbox/>
                            </div>
                            <div className="innerboxfirst">
                            <div className="facititle confaci">
                                    <h6>Contact Us</h6>
                                </div>
                                <ul className="facilist confaci">
                                    <li><a to="" className="font"><span className="faciimg"><img src="photo/placeholder.png" alt="" /></span><div> Shop No. 1, Jai Maruthi Convention Hall Building, Magadi Main Rd, near Bank Of Baroda (E Vijaya), Muthuraya Swamy Extension, Sunkadakatte, Bengaluru, Karnataka 560091</div></a></li>
                                    <li><a href="tel:" className="font"><span className="faciimg"><img src="photo/phone-call.png" alt="" /></span><div>90351 22020</div></a></li>
                                    <li><a to="" className="font"><span className="faciimg"><img src="photo/email-1.png" alt="" /></span><div>vcarescan@gmail.com</div></a></li>
                               </ul>
                            </div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>)
}
export default Colonologycon