import { memo } from "react"
import Header from '../header';
import HeaderTop from '../Header-top';
import MobileMenu from '../Mobile-menu';
import Footer from '../Footer';
import ScrollTop from '../Scrolltop';
import { useState } from 'react';
import Mainscan from '../mainscantext/MainScan';
import Scantestbut from '../mainscantext/Scantestbut';
import { useEffect } from 'react';
import AboutBanner from "../Aboutbanner";
import Newsection from "../Newscanningsection/Newsection";
import Scaninfobox from "./Scaninfobox";
import Bannerofscanner from "./Bannerofscanner";

function Newscan(){
    const [foot, setFoot]=useState("none");
    const [disnone,setDisnone]=useState("none");
    const [navbar, setNavbar]=useState("flex");
    const [scan, setScan]=useState("none");
    const [scanButton, setScanButton]=useState("block")
    useEffect(()=>{
      window.scrollTo({top:0,left:0,behavior:"smooth"})
    },[])
    return (<>
      <div className='headbody'>
      <HeaderTop/>
      <Header dis={disnone} setDis={setDisnone} na={navbar} setNa={setNavbar}/>
      <Bannerofscanner/>
      <Scaninfobox/>
      {/* <Newsection/> */}
      <Footer foot={foot} setFoot={setFoot} scanButton={scanButton} setScanButton={setScanButton}/>
      </div>
      <ScrollTop/>
      <MobileMenu dis={disnone} setDis={setDisnone} na={navbar} setNa={setNavbar}/>
      <Mainscan scan={scan} setScan={setScan}/>
      <Scantestbut scan={scan} setScan={setScan}  foot={foot} setFoot={setFoot} scanButton={scanButton} setScanButton={setScanButton}/>
    </>)
}
export default Newscan