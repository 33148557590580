import LazyLoad from "react-lazy-load";

function OurdoctorTwo() {
  return (
    <>
      <div className="section">
        <div className="container">
          <div className="title">
            <h4> Dr. Veerendra T</h4>
            <h6>Consultant Radiologist & Sonologist</h6>
          </div>
          <div className="onerow">
            <div className="docphoto">
              <LazyLoad>
                <img src="photo/doctor-two.jpg" alt="" />
              </LazyLoad>
            </div>
            <div style={{ textAlign: "left" }} className="">
              <div className="alikjbdkjv">
                <h6 style={{ margin: "0px", marginRight: "10px" }}>
                  Qualification:
                </h6>
                <p>
                  Graduated from JSS Mediacl College, DMRD from KLE Belagavi,
                  DNB trainee at KIMS Hubli, and NMR Scan Center Fellowship in
                  Fetal Medicine from BFMC.
                </p>
              </div>
              <p style={{ marginBottom: "10px" }}>
                Dr. T. Veerendra's belief is simple, being a healthcare provider
                is not only an important responsibility but a true privilege and
                every patient engagement helps make a real difference in their
                life as an individual and overall as a community.{" "}
              </p>
              <p style={{ marginBottom: "10px" }}>
                Dr. T. Veerendra has been practicing Radiologist in Karnataka
                for considerable amount of time, and is respected by his peers.
                Whether you're coming for a simple check-up or a more complex
                treatment regimen at V Care Scan Centre & Laboratory.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default OurdoctorTwo;
