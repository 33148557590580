import { memo } from "react"
import "./Newsection.css"
import LazyLoad from "react-lazy-load"
import Newcontact from "./Newcontact"
import { useState } from "react"
import Newinfobox from "../Newscan/Newinfobox"
import Newsctioninfoer from "./Newsctioninfoer"
function Newsection(){
    const [numer, setNumer]=useState(0)
    const [scan, setScan]=useState("Ct Scan")
    const menu=[
        {
            name:"Ct Scan",
            content:"A CT scan, also known as computed tomography scan or CAT scan, is a medical imaging procedure that uses X-rays and advanced computer technology to create detailed cross-sectional images of the body. "
        },
        {
            name:"Obstetric Ultrasound",
            content:"Obstetric ultrasound, also known as prenatal or pregnancy ultrasound, is a medical imaging technique used to visualize and monitor the developing fetus and the mother's reproductive organs during pregnancy."
        },
        {
            name:"Vascular Colour Doppler",
            content:"Vascular color Doppler, also known as color Doppler ultrasound, is a medical imaging technique that combines traditional ultrasound imaging with color mapping to visualize blood flow within blood vessels."
        },
        {
            name:"General Ultrasound",
            content:"General ultrasound, also known as diagnostic ultrasound or sonography, is a non-invasive medical imaging technique that uses high-frequency sound waves to produce real-time images of internal organs, tissues, and structures within the body."
        },
        {
            name:"Prenatal Marker",
            content:"In prenatal care, a prenatal marker, also known as a prenatal screening marker or prenatal test marker, refers to a specific substance or measurement detected during prenatal screening tests."
        },
        {
            name:"Cardiology",
            content:"Cardiology is a branch of medical science that focuses on the study and management of disorders related to the heart and the circulatory system. Medical professionals who specialize in cardiology are called cardiologists. They are trained to diagnose, treat, and prevent various heart conditions, ensuring optimal heart health for their patients."
        },
    ]
    return (<>
    <div className="sections pt-4">
        <div className="containers maharajar">
            <div className="rows ueifujbse">
                <div className="titles">
                    <h1>Scannings & Tests</h1>
                </div>
                <div className="titlescon">
                    <p>
                At V Care Scan Centre & Laboratory, we promise to deliver our patients the ultimate medical diagnostics services. This includes delivering the highest quality Imaging, laboratory and comprehensive health check services with respect, courtesy and compassion.</p>
                </div>
            </div>
            <div className="rowss">
                    
                <div>
                {
                    menu.map((elem,num)=>{
                        const {name, content}=elem
                        {
                            return(<>
                            <div onClick={()=>{
                                setNumer(num)
                                setScan(name)
                            }} className="firstboxs">
                    <div className={
                            num===numer?"innerboxs lllheight":"innerboxss lllheight"
                        }>
                        <div style={{marginBottom:"0px",lineHeight:"28px"}} className={
                            num===numer?"imgebox":"imgeboxs"
                        }>
                            <LazyLoad className={
                            num===numer?"widthinm":"widthinms"
                        }>
                                <img src={
                            num===numer?"photo/ct-scan.png":"photo/tomography.png"
                        } alt="" />
                            </LazyLoad>
                            <h5>{name}</h5>
                        </div>
                    </div>
                </div>
                            </>)
                        }
                    }
                    )
                }
                </div>
                <div>
                {
                    menu.map((elem,num)=>{
                        const {name, content}=elem
                        {
                            if(num===numer){
                                return(<>
                                    <div onClick={()=>{
                                        setNumer(num)
                                    }} className="firstboxs lankbigger">
                            <div className={
                                    num===numer?"innerboxs":"innerboxss"
                                }>
                                <div className={
                                    num===numer?"imgebox":"imgeboxs"
                                }>
                                    <LazyLoad className={
                                    num===numer?"widthinm":"widthinms"
                                }>
                                        <img src={
                                    num===numer?"photo/ct-scan.png":"photo/tomography.png"
                                } alt="" />
                                    </LazyLoad>
                                    <h5>{name}</h5>
                                </div>
                                <div className={
                                    num===numer?"namesss":"namessss"
                                }>
                                    <p>{content}</p>
                                </div>
                            </div>
                        </div>
                        <Newcontact num={num} numer={numer} scans={scan}/>
                                    </>)
                            }
                        }
                    }
                    )
                }
                </div>
            </div>
        </div>
        <div className="yuvarajkumar">
            <Newsctioninfoer/>
            <Newinfobox/>
        </div>
    </div>
    </>)
}
export default memo(Newsection)