import { useEffect, useState } from "react";
import scan from "./Scannercontent";
import LazyLoad from "react-lazy-load";
function ScannerBody(props){
const [scanner,setScanner]=useState(scan);
useEffect(()=>{
    window.addEventListener("scroll",()=>{
        const updatefilter=scan.filter((first)=>{
            return first.name===props.scantittle;
    })
    setScanner(updatefilter);
    })
    const updatefilter=scan.filter((first)=>{
            return first.name===props.scantittle;
    })
    setScanner(updatefilter);
})
return (<>  {  
scanner.map((elem)=>{
    const {name, img, contone, conttwo}= elem;
return (<>
<div className="section">
        <div className="container width topmargin">
    <div className="pack">
<div className="packone">
    <LazyLoad>
    <img src={img} alt="" />
    </LazyLoad>
</div>
<div className="packone packpadd texthospital">
    <div className="width packmar">
        <h3>{name}</h3>
        <p>{contone}</p>
        <p>{conttwo}</p>
    </div>
</div>
</div>
        </div>
</div>
</>)

})}</>)
}
export default ScannerBody;