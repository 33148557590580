import LazyLoad from "react-lazy-load";

function Obstricsallservice(){
    const menu=[
        {
            img:"photo/highriskpre.jpg",
            name:"High risk pregnancy",
            info:"A high-risk pregnancy is one that threatens the health or life of the mother or her fetus. It often requires specialized care from specially trained providers.",
        },
        {
            img:"photo/laparoscopic.jpg",
            name:"Laparoscopic Surgery",
            info:"Laparoscopic surgery is a surgical technique in which short, narrow tubes (trochars) are inserted into the abdomen through small (less than one centimeter) incisions. Through these trochars, long, narrow instruments are inserted. The surgeon uses these instruments to manipulate, cut, and sew tissue",
        },
        {
            img:"photo/predeli.jpg",
            name:"Normal delivery",
            info:"Normal vaginal birth is the childbirth process which takes place without any form of medical intervention. Nowadays to alleviate the pain and speed up the delivery process medications might be used (you can choose not to opt for any medical intervention).",
        },
        {
            img:"photo/presacn.jpg",
            name:"Placental Problems",
            info:"A tissue that connects the developing fetus and the mother is called placenta. The developmental problems in the placenta may cause placental issues.",
        },
        {
            img:"photo/prepre.jpg",
            name:"Early Pregnancy Care",
            info:"The food you eat during the first trimester of your pregnancy must be really healthy. Include fresh leafy vegetables, sweet potatoes, fish and seafood, dairy items like yoghurt, chickpeas, lentils, eggs etc. in your diet and stay away from processed food items. Also, keep yourself hydrated all through the day.",
        },
        {
            img:"photo/miscarriage.jpg",
            name:"Miscarriage, Abortion",
            info:"For about 70 to 90 percent of women, this treatment works within 24 hours. Surgical treatment. Another option is a minor surgical procedure called suction dilation and curettage (D&C). During this procedure, your health care provider dilates your cervix and removes tissue from the inside of your uterus.",
        },
        {
            img:"photo/pregscan.jpg",
            name:"Pregnancy Test",
            info:"A pregnancy test is used to determine whether a female is pregnant or not. The two primary methods are testing for the female pregnancy hormone in blood or urine using a pregnancy test kit, and scanning with ultrasonography.",
        },
        {
            img:"photo/fetal.jpg",
            name:"Fetal Assessment",
            info:"It looks for any increases or decreases in the baby’s heartbeat. It also checks how much the baby’s heart rate changes. The average fetal heart rate is between 110 and 160 beats per minute. The fetal heart rate may change as the baby responds to conditions in the uterus.",
        },
        {
            img:"photo/multipre.jpg",
            name:"Multiple Pregnancy Management",
            info:"Management of multiple pregnancy may include the following: Increased nutrition. Mothers carrying two or more fetuses need more calories, protein, and other nutrients, including iron. Higher weight gain is also recommended for multiple pregnancy.",
        },
        {
            img:"photo/preeclampsia.jpg",
            name:"Preeclampsia",
            info:"Pre-eclampsia usually begins after 20 weeks of pregnancy in a woman whose blood pressure had been normal. It can lead to serious, even fatal, complications for both mother and baby.",
        },
        {
            img:"photo/familyf.jpg",
            name:"Family Planning",
            info:"Family planning is the consideration of the number of children a person wishes to have, including the choice to have no children, and the age at which they wish to have them. Things that may play a role on family planning decisions include marital situation, career or work considerations, financial situations.",
        },
        {
            img:"photo/genetic.jpg",
            name:"Genetic Counselling",
            info:"Genetic counseling is the process of investigating individuals and families affected by or at risk of genetic disorders to help them understand and adapt to the medical, psychological and familial implications of genetic contributions to disease",
        },
    ]
    return (
        <>
        <div className="section">
        <div className="container">
            <div style={{marginBottom:"40px"}} className="title title-fac">
                <h3>Obstetrics Treatments</h3>
            </div>
            <div className="rowfacility">
                {
                    menu.map((elem)=>{
                        const {img, name, info}=elem
                        return (<>
                        <div>
                    <LazyLoad className="lazy">
                    <img className="facilityimg" src={img} alt="" />
                    </LazyLoad>
                    <h6>{name}</h6>
                    <p>{info}</p>
                </div>
                        </>)
                    })
                }
            </div>
        </div>
    </div>
        </>
    )
}
export default Obstricsallservice;