import LazyLoad from "react-lazy-load";
import { Link } from "react-router-dom";

function Scannerbanner(){
    return (<>
    <LazyLoad>
        <div className="banner scannerbanner" >
            <div>
                <h4>Scannings & Tests</h4>
                <div>
                    <Link to="/">Home </Link>/
                    <Link to=""> Scannings & Tests</Link>
                </div>
            </div>
        </div>
        </LazyLoad>
    </>)
}
export default Scannerbanner;