const scan=[
    {
        name:"Fetal echo",
        img:"photo/aa.jpg",
        contone:"Fetal echocardiography, also known as fetal echo, is a medical imaging technique that uses ultrasound technology to evaluate the heart of a developing fetus. This specialized type of ultrasound is typically performed during pregnancy to detect potential structural or functional abnormalities in the developing heart.",
        conttwo:"During a fetal echo, a trained medical professional uses a handheld transducer to produce high-frequency sound waves that bounce off the developing fetal heart. These sound waves create detailed images of the heart that can be used to assess its structure, function, and blood flow. The exam is typically performed between 18 and 22 weeks of gestation, although it can be done earlier or later depending on the specific circumstances. Fetal echo is considered a safe and non-invasive way to evaluate the developing heart and can provide valuable information to help diagnose and manage potential heart problems in the fetus. It is often recommended for women who have a family history of congenital heart disease, have previously had a child with a heart defect, or have other risk factors for heart problems in their developing fetus.",
    },
    {
        name:"3D/4D scanning for pregnancy",
        img:"photo/ab.jpg",
        contone:"3D and 4D scanning are advanced ultrasound techniques that allow for a more detailed view of the developing fetus during pregnancy. 3D ultrasound uses sound waves to create a 3-dimensional image of the fetus, while 4D ultrasound adds the element of time, allowing for real-time 3D images of the fetus in motion. This allows parents and medical professionals to see more detailed images of the fetus, including facial features, hands, and feet.",
        conttwo:"While 3D and 4D scans can provide a more detailed view of the developing fetus, they are not routinely used for medical purposes. Instead, they are often used for entertainment purposes, such as allowing parents to see their baby's features before birth. It is important to note that 3D and 4D scans are not considered a necessary part of routine prenatal care and should not replace standard prenatal ultrasound exams. However, in some cases, they may be recommended for medical purposes, such as to evaluate fetal abnormalities or to guide certain medical procedures. ",
    },
    {
        name:"3D/4D scanning for Gynaecology",
        img:"photo/ac.jpg",
        contone:"3D and 4D scanning can also be used in gynecology to evaluate the pelvic organs, such as the uterus, ovaries, and cervix. This type of ultrasound is commonly known as gynecological ultrasound. A 3D gynecological ultrasound uses sound waves to create a 3-dimensional image of the pelvic organs, while a 4D gynecological ultrasound adds the element of time, allowing for real-time 3D images of the pelvic organs in motion. This allows medical professionals to see more detailed images of the pelvic organs and can be helpful in diagnosing various gynecological conditions.",
        conttwo:"It is important to note that while 3D and 4D gynecological ultrasound can provide more detailed images of the pelvic organs, they are not typically used as a routine screening tool and are reserved for cases where additional information is needed beyond a standard 2D ultrasound. As with any medical imaging procedure, it is important to ensure that the ultrasound provider is properly trained and accredited.",
    },
    {
        name:"Pregnancy scanning",
        img:"photo/ad.jpg",
        contone:"Pregnancy scanning, also known as prenatal ultrasound, is a non-invasive imaging technique that uses sound waves to create images of the developing fetus during pregnancy. Prenatal ultrasound is routinely used as a part of prenatal care to evaluate the health and growth of the fetus and to detect any potential abnormalities. During a prenatal ultrasound, a trained medical professional uses a handheld transducer to produce high-frequency sound waves that bounce off the developing fetus. These sound waves create detailed images of the fetus, including its size, position, and anatomy.",
        conttwo:"Prenatal ultrasound is considered safe for both the mother and the developing fetus and does not use ionizing radiation like X-rays or CT scans. However, as with any medical procedure, there are some risks and limitations associated with prenatal ultrasound, and it should only be performed by trained and qualified medical professionals.",
    },
    {
        name:"Anomaly scanning",
        img:"photo/ae.jpg",
        contone:"Anomaly scanning, also known as a mid-pregnancy ultrasound, is a specialized prenatal ultrasound performed between 18 and 22 weeks of pregnancy to screen for any structural abnormalities in the developing fetus. This type of ultrasound is typically performed as a routine part of prenatal care in many countries. During the anomaly scan, a trained medical professional uses a handheld transducer to produce high-frequency sound waves that bounce off the developing fetus. These sound waves create detailed images of the fetus, including its size, position, and anatomy. The medical professional will then examine the images to check for any abnormalities in the fetal anatomy, such as problems with the brain, spine, heart, kidneys, or limbs.",
        conttwo:"Anomaly scanning is considered an important part of prenatal care because it can help identify any potential problems early on in the pregnancy, allowing for prompt and appropriate management. In some cases, additional testing or monitoring may be recommended if an anomaly is detected. ",
    },
    {
        name:"NT scanning",
        img:"photo/af.jpg",
        contone:"NT scanning, also known as nuchal translucency screening, is a prenatal ultrasound performed between 11 and 14 weeks of pregnancy to assess the risk of chromosomal abnormalities such as Down syndrome and other genetic disorders. During an NT scan, a trained medical professional uses ultrasound to measure the thickness of a small pocket of fluid at the back of the developing fetus's neck. This measurement is known as the nuchal translucency (NT) measurement. An increased NT measurement may indicate an increased risk for chromosomal abnormalities. In addition to the NT measurement, the medical professional will also evaluate the fetal anatomy, including the heart, brain, spine, and other organs, to identify any other potential abnormalities.",
        conttwo:"NT scanning is considered a non-invasive and safe screening tool for chromosomal abnormalities. However, it is important to note that NT scanning does not provide a definitive diagnosis of a chromosomal abnormality, and additional testing, such as chorionic villus sampling (CVS) or amniocentesis, may be recommended for further evaluation.",
    },
    {
        name:"Viability scanning",
        img:"photo/ag.jpg",
        contone:"A viability scan, also known as an early pregnancy ultrasound, is a prenatal ultrasound that is usually performed in the first trimester of pregnancy to confirm the viability of the pregnancy and to estimate the gestational age of the fetus. During a viability scan, a trained medical professional uses ultrasound to create images of the developing fetus. The medical professional will measure the fetal crown-rump length (CRL) to estimate the gestational age of the fetus and to confirm that the pregnancy is viable (meaning that the fetus has a heartbeat).",
        conttwo:"Viability scanning may be recommended for women who have experienced vaginal bleeding, cramping, or other symptoms of miscarriage, as well as for those who have a history of ectopic pregnancy or other high-risk pregnancies. Viability scanning is considered safe and non-invasive, and does not use ionizing radiation like X-rays or CT scans. However, as with any medical procedure, there are some risks and limitations associated with prenatal ultrasound, and it should only be performed by trained and qualified medical professionals.",
    },
    {
        name:"Pelvis scanning",
        img:"photo/ba.jpg",
        contone:"Pelvic scanning, also known as pelvic ultrasound, is a non-invasive imaging test that uses high-frequency sound waves to create images of the structures and organs in the pelvic region of the body, including the uterus, ovaries, bladder, and cervix. Pelvic scanning is commonly used for a variety of purposes, including to evaluate pelvic pain, abnormal vaginal bleeding, or infertility. During a pelvic ultrasound, a trained medical professional applies a small amount of gel to the skin of the lower abdomen or inserts a small probe into the vagina to create images of the pelvic structures. The images can help identify abnormalities such as cysts, fibroids, or tumors in the reproductive organs, as well as detect any signs of inflammation or infection in the pelvic region.",
        conttwo:"Pelvic scanning may be performed as a part of routine gynecologic care, or may be recommended if there are symptoms or concerns related to the pelvic organs. The results of a pelvic ultrasound can help guide further diagnostic testing or treatment recommendations, if necessary.",
    },
    {
        name:"Obstetrics scanning",
        img:"photo/bb.jpg",
        contone:"Obstetrics scanning, also known as prenatal ultrasound, is a non-invasive imaging test that uses high-frequency sound waves to create images of the developing fetus in the uterus during pregnancy. Obstetrics scanning is commonly used for a variety of purposes, including to evaluate fetal growth, estimate gestational age, identify any structural abnormalities or anomalies, and monitor the health and well-being of the developing fetus. ",
        conttwo:"During an obstetrics scan, a trained medical professional applies a small amount of gel to the skin of the abdomen or inserts a small probe into the vagina to create images of the fetus and the structures in the uterus. The images can help identify abnormalities such as congenital heart defects, neural tube defects, or other structural abnormalities in the developing fetus, as well as evaluate the placenta and amniotic fluid levels. Obstetrics scanning is a routine part of prenatal care, and may be performed at various stages throughout the pregnancy, depending on the individual needs and circumstances of the mother and fetus. The results of an obstetrics scan can help guide further diagnostic testing or treatment recommendations, if necessary, and can provide reassurance and information to expectant parents.",
    },
    {
        name:"Scanning for pain abdomen",
        img:"photo/bc.jpg",
        contone:"Scanning for pain in the abdomen may be performed using various imaging modalities, depending on the suspected cause of the pain and the individual circumstances of the patient. An abdominal ultrasound is a non-invasive imaging test that uses high-frequency sound waves to create images of the organs and structures in the abdomen, including the liver, gallbladder, pancreas, kidneys, and spleen. It can be used to evaluate abdominal pain and identify potential causes, such as gallstones, kidney stones, or liver disease. A CT scan of the abdomen is a more detailed imaging test that uses X-rays and computer technology to create cross-sectional images of the organs and structures in the abdomen. It can be used to evaluate abdominal pain and identify potential causes, such as tumors, inflammation, or obstruction. Magnetic resonance imaging (MRI) of the abdomen is a non-invasive imaging test that uses magnetic fields and radio waves to create detailed images of the organs and structures in the abdomen. It can be used to evaluate abdominal pain and identify potential causes, such as tumors, inflammation, or abnormalities in the blood vessels.",
        conttwo:"",
    },
    {
        name:"Scanning for menstrual pain",
        img:"photo/bd.jpg",
        contone:"Scanning is typically not the first line of investigation for menstrual pain. Menstrual pain, also known as dysmenorrhea, is a common symptom experienced by many women during their menstrual cycle. The pain can range from mild to severe and may be caused by a variety of factors, including hormonal changes, inflammation, or underlying medical conditions such as endometriosis or uterine fibroids. ",
        conttwo:"In some cases, imaging tests such as ultrasound or MRI may be recommended to evaluate menstrual pain, especially if other symptoms or signs suggest an underlying medical condition. These imaging tests can help identify potential causes of menstrual pain, such as ovarian cysts, uterine fibroids, or endometriosis. However, other diagnostic tests or procedures may be recommended first, depending on the individual circumstances and symptoms. These may include a physical exam, blood tests, or pelvic exams to evaluate the reproductive organs. It is important to discuss any concerns or symptoms related to menstrual pain with a healthcare provider, who can help determine the appropriate diagnostic tests or treatments.",
    },
    {
        name:"Renal doppler scanning",
        img:"photo/be.jpg",
        contone:"Renal Doppler scanning is a type of medical imaging that uses sound waves to evaluate the blood flow to and from the kidneys. This non-invasive test can help diagnose and monitor various conditions that affect the kidneys and their blood vessels, such as renal artery stenosis, renal vein thrombosis, and renal transplant dysfunction.",
        conttwo:"During a renal Doppler scan, a special ultrasound machine is used to bounce high-frequency sound waves off the blood vessels in the kidneys, producing images and sounds that can be analyzed by a trained medical professional. The images can show the direction and speed of blood flow, as well as any areas of narrowing or blockage in the blood vessels. Renal Doppler scanning is generally considered a safe and painless procedure, and does not involve exposure to ionizing radiation like X-rays or CT scans. The procedure typically takes about 30-60 minutes to complete, and does not require any special preparation or recovery time. Renal Doppler scanning can provide valuable information about the health and function of the kidneys, and may be used to monitor the progress of treatment or identify potential complications. It is typically performed by a trained radiologist or ultrasound technician, and the results are interpreted by a healthcare provider.",
    },
    {
        name:"Carotid Doppler Scanning",
        img:"photo/bf.jpg",
        contone:"Carotid Doppler scanning is a non-invasive imaging test that uses sound waves to evaluate the blood flow in the carotid arteries, which are the major blood vessels that supply oxygenated blood to the brain. This test is typically used to evaluate the presence and severity of blockages or narrowing in the carotid arteries, which can increase the risk of stroke. During a carotid Doppler scan, a special ultrasound machine is used to bounce high-frequency sound waves off the blood vessels in the neck, producing images and sounds that can be analyzed by a trained medical professional. The images can show the direction and speed of blood flow, as well as any areas of narrowing or blockage in the carotid arteries.",
        conttwo:"Carotid Doppler scanning is generally considered a safe and painless procedure, and does not involve exposure to ionizing radiation like X-rays or CT scans. The procedure typically takes about 30-60 minutes to complete, and does not require any special preparation or recovery time.",
    },
    {
        name:"Venous doppler scanning",
        img:"photo/bg.jpg",
        contone:"Venous doppler scanning is a diagnostic imaging test that uses ultrasound technology to evaluate the blood flow through the veins in the body. It is a non-invasive and painless procedure that is commonly used to assess blood clots, varicose veins, and other conditions that affect the veins. During a venous doppler scan, a technician will apply a gel to the skin over the area being examined, and then use a small handheld device called a transducer to emit high-frequency sound waves into the body. These sound waves bounce off the veins and are picked up by the transducer, which creates images of the blood flow on a monitor.",
        conttwo:"The images produced by the venous doppler scan can help identify any blockages or abnormalities in the veins, such as blood clots or narrowed areas. This information can be useful in diagnosing conditions like deep vein thrombosis (DVT), varicose veins, and chronic venous insufficiency. Overall, venous doppler scanning is a safe and effective tool for evaluating the blood flow in the veins, and can provide valuable information to aid in the diagnosis and treatment of various venous disorders.",
    },
    {
        name:"Arterial doppler",
        img:"photo/ca.jpg",
        contone:"Arterial doppler is a non-invasive diagnostic test that uses ultrasound technology to evaluate the blood flow in the arteries of the body. It is a painless procedure that is commonly used to assess the blood flow in the arms and legs and is particularly useful in the diagnosis of peripheral arterial disease (PAD). During an arterial doppler, a technician will apply a gel to the skin over the area being examined and use a small handheld device called a transducer to emit high-frequency sound waves into the body. These sound waves bounce off the arteries and are picked up by the transducer, which creates images of the blood flow on a monitor.",
        conttwo:"The images produced by the arterial doppler can help identify any blockages or narrowing of the arteries, which can cause reduced blood flow to the extremities. This information can be useful in diagnosing and monitoring PAD, which is a common condition that can lead to pain, numbness, and other complications in the legs and feet. Overall, arterial doppler is a safe and effective tool for evaluating the blood flow in the arteries and can provide valuable information to aid in the diagnosis and treatment of various arterial disorders.",
    },
    {
        name:"Obstetrics doppler",
        img:"photo/cb.jpg",
        contone:"Obstetrics doppler is a non-invasive diagnostic test that uses ultrasound technology to evaluate the blood flow in the fetus and placenta during pregnancy. It is a painless procedure that is commonly used to monitor the health and well-being of the developing baby. ",
        conttwo:"During an obstetrics doppler, a technician will apply a gel to the skin over the abdomen and use a small handheld device called a transducer to emit high-frequency sound waves into the body. These sound waves bounce off the blood cells in the fetus and placenta and are picked up by the transducer, which creates images of the blood flow on a monitor. The images produced by the obstetrics doppler can help evaluate the blood flow to the fetus and placenta and identify any potential problems, such as fetal growth restriction or placental insufficiency. This information can be useful in guiding medical management, such as timing of delivery or interventions to optimize fetal growth and well-being. verall, obstetrics doppler is a safe and effective tool for monitoring the health of the developing fetus during pregnancy and can provide valuable information to aid in the management of high-risk pregnancies.",
    },
    {
        name:"Biophysical Profile",
        img:"photo/cc.jpg",
        contone:"A biophysical profile (BPP) is a prenatal test that is used to evaluate the well-being of the developing fetus during pregnancy. It is a non-invasive and painless test that combines ultrasound imaging and fetal heart rate monitoring to assess various aspects of fetal health. ",
        conttwo:"During a BPP, a technician will use ultrasound to evaluate the fetus's breathing movements, body movements, muscle tone, and amniotic fluid volume. The technician will also monitor the fetal heart rate. Each of these parameters is given a score, and the scores are combined to provide an overall assessment of fetal well-being. A BPP may be recommended for various reasons, such as if the mother has a high-risk pregnancy, if there is concern about fetal growth or if there is a decrease in fetal movement. The test can help identify potential problems, such as fetal distress or hypoxia, and guide appropriate management, such as delivery or further testing. Overall, a biophysical profile is a safe and effective tool for monitoring the health of the developing fetus during pregnancy and can provide valuable information to aid in the management of high-risk pregnancies.",
    },
    {
        name:"Scrotal scanning",
        img:"photo/cd.jpg",
        contone:"Scrotal scanning, also known as testicular ultrasound, is a non-invasive diagnostic imaging test that uses ultrasound technology to examine the scrotum and testicles. It is a painless and safe procedure that is commonly used to evaluate the structure and function of the testicles and surrounding tissues. During a scrotal scan, a technician will apply a gel to the skin over the scrotum and use a small handheld device called a transducer to emit high-frequency sound waves into the body. These sound waves bounce off the tissues in the scrotum and testicles and are picked up by the transducer, which creates images of the structures on a monitor.",
        conttwo:"The images produced by the scrotal scan can help identify any abnormalities, such as masses, cysts, or varicoceles, and can help diagnose conditions like testicular torsion, epididymitis, and orchitis. The test can also be used to evaluate blood flow to the testicles, which can be helpful in the diagnosis of conditions like testicular cancer. Overall, scrotal scanning is a safe and effective tool for evaluating the scrotum and testicles and can provide valuable information to aid in the diagnosis and treatment of various conditions affecting the male reproductive system.",
    },
    {
        name:"Mammography(Usg)",
        img:"photo/ce.jpg",
        contone:"Mammography and ultrasound (USG) are two different imaging modalities used in the diagnosis and screening of breast cancer.",
        conttwo:"Mammography is a low-dose X-ray imaging technique that uses specialized equipment to produce high-resolution images of the breast tissue. It is typically used for breast cancer screening in asymptomatic women over the age of 40 or for diagnostic purposes in women who have a breast lump or other symptoms. Ultrasound, on the other hand, uses high-frequency sound waves to produce images of the breast tissue. It is often used as an adjunct to mammography for diagnostic purposes, such as to evaluate a breast lump or to guide a needle biopsy. While mammography is the gold standard for breast cancer screening, ultrasound can be particularly useful in certain situations, such as for women with dense breast tissue, which can make mammography less effective. Ultrasound can also help differentiate between a fluid-filled cyst and a solid mass, which is important for guiding further evaluation. Overall, mammography and ultrasound are both valuable imaging tools in the diagnosis and screening of breast cancer, and they are often used together to provide a comprehensive evaluation of the breast tissue.",
    },
    {
        name:"Penile doppler",
        img:"photo/cf.jpg",
        contone:"Penile doppler ultrasound is a diagnostic test that is used to evaluate the blood flow to the penis. It is a non-invasive and painless procedure that uses ultrasound technology to produce images of the blood vessels in and around the penis. During a penile doppler ultrasound, a technician will apply a gel to the skin over the penis and use a small handheld device called a transducer to emit high-frequency sound waves into the body. These sound waves bounce off the blood cells in the penis and are picked up by the transducer, which creates images of the blood flow on a monitor.",
        conttwo:"The images produced by the penile doppler ultrasound can help identify any problems with blood flow to the penis, such as a blockage in the arteries or veins. This information can be useful in diagnosing conditions like erectile dysfunction (ED) and priapism. The test can also help guide treatment decisions, such as the use of medications to improve blood flow or the need for surgery to address a blockage. Overall, penile doppler ultrasound is a safe and effective tool for evaluating the blood flow to the penis and can provide valuable information to aid in the diagnosis and treatment of conditions affecting male sexual health.",
    },
    {
        name:"Abdominal scanning (USG)",
        img:"photo/cg.jpg",
        contone:"Abdominal scanning, also known as abdominal ultrasound, is a diagnostic imaging test that uses ultrasound technology to produce images of the organs and tissues in the abdomen. It is a non-invasive and painless procedure that does not involve the use of ionizing radiation. During an abdominal ultrasound, a technician will apply a gel to the skin over the abdomen and use a small handheld device called a transducer to emit high-frequency sound waves into the body. These sound waves bounce off the organs and tissues in the abdomen and are picked up by the transducer, which creates images of the structures on a monitor.",
        conttwo:"The images produced by the abdominal ultrasound can help identify any abnormalities or changes in the organs and tissues in the abdomen, such as the liver, gallbladder, pancreas, spleen, kidneys, and bladder. It can help diagnose a range of conditions, including gallstones, kidney stones, cysts, tumors, and fluid accumulation. ",
    },
    {
        name:"Neck scanning (USG)",
        img:"photo/da.jpg",
        contone:"Neck scanning, also known as neck ultrasound, is a diagnostic imaging test that uses ultrasound technology to produce images of the neck structures, such as the thyroid gland, lymph nodes, and blood vessels. It is a non-invasive and painless procedure that does not involve the use of ionizing radiation. During a neck ultrasound, a technician will apply a gel to the skin over the neck and use a small handheld device called a transducer to emit high-frequency sound waves into the body. These sound waves bounce off the tissues in the neck and are picked up by the transducer, which creates images of the structures on a monitor.",
        conttwo:"The images produced by the neck ultrasound can help identify any abnormalities or changes in the neck structures, such as nodules or cysts in the thyroid gland, enlarged lymph nodes, or abnormalities in the blood vessels. It can help diagnose a range of conditions, including thyroid nodules, thyroid cancer, lymphoma, and carotid artery disease. ",
    },
    {
        name:"Thyroid scanning (USG)",
        img:"photo/db.jpg",
        contone:"Thyroid scanning, also known as thyroid ultrasound, is a diagnostic imaging test that uses ultrasound technology to produce images of the thyroid gland. It is a non-invasive and painless procedure that does not involve the use of ionizing radiation. During a thyroid ultrasound, a technician will apply a gel to the skin over the neck and use a small handheld device called a transducer to emit high-frequency sound waves into the body. These sound waves bounce off the thyroid gland and are picked up by the transducer, which creates images of the gland on a monitor.",
        conttwo:"The images produced by the thyroid ultrasound can help identify any abnormalities or changes in the thyroid gland, such as nodules or cysts. It can also help determine the size and shape of the gland and evaluate the blood flow to the gland. Thyroid ultrasound is a safe and effective tool for evaluating the thyroid gland and can provide valuable information to aid in the diagnosis and treatment of various medical conditions affecting the thyroid. It is often used as a first-line imaging tool for evaluating thyroid nodules, and it can help guide further diagnostic and treatment decisions.",
    },
    {
        name:"Scanning for pelvic pain",
        img:"photo/dc.jpg",
        contone:"Pelvic pain scanning, also known as pelvic ultrasound, is a diagnostic imaging test that uses ultrasound technology to produce images of the organs and tissues in the pelvis. It is a non-invasive and painless procedure that does not involve the use of ionizing radiation. During a pelvic ultrasound, a technician will apply a gel to the skin over the lower abdomen and use a small handheld device called a transducer to emit high-frequency sound waves into the body. These sound waves bounce off the organs and tissues in the pelvis and are picked up by the transducer, which creates images of the structures on a monitor.",
        conttwo:"The images produced by the pelvic ultrasound can help identify any abnormalities or changes in the pelvic organs and tissues, such as the uterus, ovaries, bladder, and rectum. It can help diagnose a range of conditions, including ovarian cysts, fibroids, endometriosis, and pelvic inflammatory disease. Pelvic ultrasound is a safe and effective tool for evaluating the organs and tissues in the pelvis and can provide valuable information to aid in the diagnosis and treatment of various medical conditions causing pelvic pain. It is often used as a first-line imaging tool for evaluating pelvic pain and can help guide further diagnostic and treatment decisions.",
    },
    {
        name:"Pleural tapping USG guided",
        img:"photo/dd.jpg",
        contone:"USG-guided pleural tapping, also known as thoracentesis, is a medical procedure that uses ultrasound technology to guide the placement of a needle through the chest wall into the pleural space to remove excess fluid or air. It is a minimally invasive procedure that can be performed in an outpatient setting, and it is used to diagnose and treat a variety of medical conditions that affect the lungs and chest cavity. ",
        conttwo:"During the procedure, a healthcare provider will use an ultrasound machine to locate the fluid or air in the pleural space and guide the placement of a needle into the space. The needle is used to remove a small sample of fluid or air for diagnostic testing or to relieve symptoms caused by excess fluid or air, such as shortness of breath. USG-guided pleural tapping is a safe and effective tool for diagnosing and treating a variety of medical conditions affecting the chest and lungs, such as pleural effusion, pneumothorax, and hemothorax. It allows for accurate and precise placement of the needle, reducing the risk of complications and increasing the success of the procedure.",
    },
    {
        name:"Ultrasound Scan",
        img:"photo/de.jpg",
        contone:"Ultrasound scanning, also known as ultrasonography, is a diagnostic imaging technique that uses high-frequency sound waves to create images of the inside of the body. It is a non-invasive and painless procedure that does not involve the use of ionizing radiation, making it safe for use in a variety of medical settings. During an ultrasound scan, a technician will apply a gel to the skin over the area being examined and use a small handheld device called a transducer to emit high-frequency sound waves into the body. These sound waves bounce off the tissues and organs in the body and are picked up by the transducer, which creates images of the structures on a monitor.",
        conttwo:"The images produced by the ultrasound scan can help identify any abnormalities or changes in the tissues and organs being examined. Ultrasound scans can be used to diagnose a wide range of medical conditions, including pregnancy, gallbladder disease, liver disease, kidney disease, and cancer. Ultrasound scans are safe, non-invasive, and generally well-tolerated by patients. They are often used as a first-line imaging tool for evaluating a variety of medical conditions and can help guide further diagnostic and treatment decisions.",
    },
    {
        name:"Blood Tests",
        img:"photo/df.jpg",
        contone:"Blood tests, also known as blood work, are laboratory tests that analyze a sample of blood to provide information about a person's health. Blood tests can help diagnose medical conditions, monitor treatment effectiveness, and screen for health problems. Blood tests typically involve taking a sample of blood from a vein in the arm or finger. The blood is then sent to a laboratory for analysis. The type of blood test ordered depends on the purpose of the test and the medical condition being evaluated.",
        conttwo:"Complete blood count (CBC) - This test measures the number and types of blood cells in the body and can help diagnose conditions such as anemia and infections. Blood chemistry tests - These tests measure the levels of various substances in the blood, such as glucose, cholesterol, and electrolytes. They can help diagnose conditions such as diabetes and kidney disease. Blood clotting tests - These tests measure how quickly the blood clots and can help diagnose conditions such as clotting disorders. Hormone tests - These tests measure the levels of hormones in the blood, such as thyroid hormones and reproductive hormones.",
    },
    {
        name:"Fetal Medicine",
        img:"photo/dg.jpg",
        contone:"Fetal medicine is a branch of medicine that focuses on the diagnosis, treatment, and management of fetal and maternal health conditions during pregnancy. Fetal medicine specialists work closely with obstetricians, gynecologists, and other healthcare providers to provide specialized care to pregnant women and their developing fetuses. Fetal medicine involves a variety of diagnostic and therapeutic techniques, including ultrasound, fetal echocardiography, amniocentesis, chorionic villus sampling, and fetal surgery. These techniques are used to diagnose and manage a range of fetal health conditions, such as congenital anomalies, chromosomal abnormalities, and fetal growth restriction.",
        conttwo:"Fetal medicine also involves the management of maternal health conditions that can impact fetal health, such as diabetes, hypertension, and infectious diseases. Fetal medicine specialists work with obstetricians and other healthcare providers to manage these conditions and ensure the best possible outcomes for both the mother and the developing fetus. ",
    },
    {
        name:"Consultations",
        img:"photo/ea.jpg",
        contone:"A medical consultation is a meeting between a patient and a healthcare provider to discuss the patient's health concerns and determine the best course of treatment. Consultations can take place in person, over the phone, or via telemedicine. During a consultation, the healthcare provider will typically ask the patient about their medical history, symptoms, and any medications they are currently taking. They may also perform a physical examination and order diagnostic tests to help diagnose and treat the patient's health condition.",
        conttwo:"Consultations can be scheduled for a variety of medical concerns, such as acute illnesses, chronic medical conditions, mental health concerns, and preventative care. They can also be used to monitor the progress of ongoing treatment plans and make adjustments as needed. Consultations are an important part of medical care and can help patients receive timely and appropriate treatment for their health concerns. They provide an opportunity for patients to ask questions, express concerns, and work collaboratively with their healthcare provider to manage their health and wellbeing.",
    },
    {
        name:"Electrocardiogram",
        img:"photo/eb.jpg",
        contone:"An electrocardiogram (ECG or EKG) is a non-invasive medical test that measures the electrical activity of the heart. It is commonly used to diagnose and monitor heart conditions, such as arrhythmias, heart attacks, and heart failure. During an ECG, small electrodes are attached to the skin on the chest, arms, and legs. The electrodes detect the electrical signals generated by the heart and transmit them to a machine that records the information as a graph or waveform. The ECG provides information about the heart's rhythm, rate, and the size and position of the heart's chambers.",
        conttwo:"ECGs are safe, painless, and generally take only a few minutes to perform. They are typically performed in a healthcare provider's office, clinic, or hospital setting. ECGs are an important diagnostic tool that can help identify heart conditions and guide treatment decisions. Your healthcare provider can provide more information about why an ECG may be recommended and what the results may indicate.",
    },
    {
        name:"2D Echocardiogram (Echo)",
        img:"photo/ec.jpg",
        contone:"A 2D echocardiogram, also known as an  is a type of non-invasive medical test that uses ultrasound waves to create detailed images of the heart. It is a commonly used diagnostic tool to assess the structure and function of the heart and detect various heart conditions. During a 2D echocardiogram, a healthcare provider will place a small device called a transducer on the chest, which emits sound waves that bounce off the heart. The reflected sound waves are then used to create a moving image of the heart on a monitor. The images produced by the echocardiogram can show the size and shape of the heart, the thickness and movement of the heart muscle, and the function of the heart valves.",
        conttwo:"2D echocardiograms can be used to diagnose and monitor a range of heart conditions, such as heart failure, heart valve disease, and congenital heart defects. They are safe, painless, and typically take 30 to 60 minutes to complete. Your healthcare provider can provide more information about why a 2D echocardiogram may be recommended and what the results may indicate.",
    },
    {
        name:"MCU",
        img:"photo/ed.jpg",
        contone:"MCU scan, also known as micturating cystourethrogram, is a diagnostic imaging test that uses X-rays to visualize the bladder and urethra during urination. This test is commonly used to evaluate urinary tract conditions, such as vesicoureteral reflux, in which urine flows back from the bladder to the kidneys. During an MCU scan, a contrast dye is first inserted into the bladder through a catheter. X-ray images are then taken while the patient urinates, allowing the healthcare provider to visualize the flow of urine and identify any abnormalities or obstructions.",
        conttwo:"The test typically takes about 30 minutes to complete and is performed in a hospital or clinic setting. The patient may experience some discomfort during the insertion of the catheter and the filling of the bladder with contrast dye. An MCU scan is a useful diagnostic tool that can help healthcare providers evaluate and diagnose urinary tract conditions. Your healthcare provider can provide more information about why an MCU scan may be recommended and what the results may indicate.",
    },
    {
        name:"Digital XRAY",
        img:"photo/ee.jpg",
        contone:"Digital X-ray is a type of medical imaging technology that uses digital sensors to capture images of the body's internal structures. Unlike traditional X-ray technology, which produces film images, digital X-ray uses electronic detectors to capture and store digital images, which can be viewed on a computer screen. Digital X-rays are commonly used to diagnose and monitor a variety of medical conditions, including broken bones, lung infections, and dental problems. They are safe, painless, and generally take only a few minutes to perform. Digital X-rays can be performed in a hospital, clinic, or radiology center.",
        conttwo:"The advantages of digital X-rays include faster image acquisition, lower radiation exposure, and the ability to enhance and manipulate images to improve diagnostic accuracy. Digital X-rays are also easier to store and transmit than traditional film X-rays, making it easier for healthcare providers to share images and collaborate on patient care. Your healthcare provider can provide more information about why a digital X-ray may be recommended and what the results may indicate.",
    },
]
export default scan;