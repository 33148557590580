import { memo } from "react"
import LazyLoad from "react-lazy-load"
import { Link } from "react-router-dom"

function Newfixsider(){
    return (<>
    <LazyLoad>
    <div style={{marginBottom:"0px"}} className="section bodifixa">
        <div className="containers">
             <div className="rows">
             <div className="faller filtersc jhvhjmn">
                        <from className="servers">  
                            <input className="inputers" type="text" placeholder="   Name"/>
                            <input className="inputers" type="text"  placeholder="   Phone"/>
                            <textarea className="inputerss" type="text"  placeholder="  Messages"/>
                            <div className="subber">
                                <button className="submiter">Submit</button>
                            </div>
                        </from>
                    </div>
             <div className="faller">
                <div className="titless">
                    <div>
                        
                    <h1>Please, call our customer service or get an appointment with our doctor.</h1>
                                <a href="tel:9035122020" style={{textDecoration:"none"}} className="submiter">Call Us</a>
                    </div>
                </div>
             </div>
             </div>
        </div>
    </div>
    </LazyLoad>
    </>)
}
export default memo(Newfixsider)