import { Link } from "react-router-dom"

const ScaningTest=(props)=>{
    return (
        <>
        <div className="section">
            <div className="container width">
                <div className="title-grid center">
                    <h4>Scannings & Tests</h4>
                </div>
                <div className="foot paddremove">
                <div className="footbody coloring">
                    <ul>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link to="/ultrasound"><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div className="sub">Ultrasound Scan with Color Doppler</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link to="/sacnningtextbox" onClick={()=>{
                             props.setScannertittle("Fetal echo");}}>
                            <span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div className="sub">Fetal echo</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link to="/sacnningtextbox" onClick={()=>{
                             props.setScannertittle("3D/4D scanning for pregnancy");
                        }}><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div>3D/4D scanning for pregnancy</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link to="/sacnningtextbox" onClick={()=>{
                             props.setScannertittle("3D/4D scanning for Gynaecology");
                        }}><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div>3D/4D scanning for Gynaecology</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link to="/sacnningtextbox" onClick={()=>{
                             props.setScannertittle("Pregnancy scanning");
                        }}><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div>Pregnancy scanning</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link to="/sacnningtextbox" onClick={()=>{
                             props.setScannertittle("Anomaly scanning");
                        }}><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div>Anomaly scanning</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link to="/sacnningtextbox" onClick={()=>{
                             props.setScannertittle("NT scanning");
                        }}><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div>NT scanning</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link to="/sacnningtextbox" onClick={()=>{
                             props.setScannertittle("Viability scanning");
                        }}><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div>Viability scanning</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link to="/sacnningtextbox" onClick={()=>{
                             props.setScannertittle("Pelvis scanning");
                        }}><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div>Pelvis scanning</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link to="/sacnningtextbox" onClick={()=>{
                             props.setScannertittle("Obstetrics scanning");
                        }}><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div>Obstetrics scanning</div></Link></li>
                    </ul>
                </div>
                <div className="footbody coloring">
                    <ul>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link to="/twodecho"><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div className="sub">2D Echo</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link  to="/sacnningtextbox" onClick={()=>{
                            props.setScannertittle("Scanning for pain abdomen");}}><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div>Scanning for pain abdomen</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link  to="/sacnningtextbox" onClick={()=>{
                            props.setScannertittle("Scanning for menstrual pain");}}><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div>Scanning for menstrual pain</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link  to="/sacnningtextbox" onClick={()=>{
                            props.setScannertittle("Renal doppler scanning");}}><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div>Renal doppler scanning</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link  to="/sacnningtextbox" onClick={()=>{
                            props.setScannertittle("Carotid Doppler Scanning");}}><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div>Carotid Doppler Scanning</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link  to="/sacnningtextbox" onClick={()=>{
                            props.setScannertittle("Venous doppler scanning");}}><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div>Venous doppler scanning</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link  to="/sacnningtextbox" onClick={()=>{
                            props.setScannertittle("Arterial doppler");}}><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div>Arterial doppler</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link  to="/sacnningtextbox" onClick={()=>{
                            props.setScannertittle("Obstetrics doppler");}}><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div>Obstetrics doppler</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link  to="/sacnningtextbox" onClick={()=>{
                            props.setScannertittle("Biophysical Profile");}}><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div>Biophysical Profile</div></Link></li>
                    </ul>
                </div>
                <div className="footbody coloring">
                    <ul>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link to="/threefour"><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div className="sub">3D & 4D Scan</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link  to="/sacnningtextbox" onClick={()=>{
                            props.setScannertittle("Scrotal scanning");}}><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div>Scrotal scanning</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link  to="/sacnningtextbox" onClick={()=>{
                            props.setScannertittle("Mammography(Usg)");}}><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div>Mammography(Usg)</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link  to="/sacnningtextbox" onClick={()=>{
                            props.setScannertittle("Penile doppler");}}><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div>Penile doppler</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link  to="/sacnningtextbox" onClick={()=>{
                            props.setScannertittle("Abdominal scanning (USG)");}}><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div>Abdominal scanning (USG)</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link  to="/sacnningtextbox" onClick={()=>{
                            props.setScannertittle("Neck scanning (USG)");}}><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div>Neck scanning (USG)</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link  to="/sacnningtextbox" onClick={()=>{
                            props.setScannertittle("Thyroid scanning (USG)");}}><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div>Thyroid scanning (USG)</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link  to="/sacnningtextbox" onClick={()=>{
                            props.setScannertittle("Scanning for pelvic pain");}}><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div>Scanning for pelvic pain</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link  to="/sacnningtextbox" onClick={()=>{
                            props.setScannertittle("Pleural tapping USG guided");}}><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div>Pleural tapping USG guided</div></Link></li>
                    </ul>
                </div>
                <div className="footbody coloring">
                    <ul className="">
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link to="/digitalxay"><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div className="sub">Digital X-Ray</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link  to="/sacnningtextbox" onClick={()=>{
                            props.setScannertittle("Ultrasound Scan");}}><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div>Ultrasound Scan</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link  to="/sacnningtextbox" onClick={()=>{
                            props.setScannertittle("Blood Tests");}}><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div>Blood Tests</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link  to="/sacnningtextbox" onClick={()=>{
                            props.setScannertittle("Fetal Medicine");}}><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div>Fetal Medicine</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link  to="/sacnningtextbox" onClick={()=>{
                            props.setScannertittle("Consultations");}}><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div>Consultations</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link  to="/sacnningtextbox" onClick={()=>{
                            props.setScannertittle("Electrocardiogram");}}><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div>Electrocardiogram</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link  to="/sacnningtextbox" onClick={()=>{
                            props.setScannertittle("2D Echocardiogram (Echo)");}}><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div>2D Echocardiogram (Echo)</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link  to="/sacnningtextbox" onClick={()=>{
                            props.setScannertittle("MCU");}}><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div>MCU</div></Link></li>
                        <li onClick={()=>{
                            props.setScan("none")
                        }}><Link  to="/sacnningtextbox" onClick={()=>{
                            props.setScannertittle("Digital XRAY");}}><span className="whiter"><img src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt=""/></span><div>Digital XRAY</div></Link></li>
                    </ul>
                </div>
            </div>
            </div>
        </div>
        </>
    )
}
export default ScaningTest