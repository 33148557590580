function Callusgetapp(){
    return (<>
    <div className="section">
        <div className="container width">
            <div className="callbox">
                <div className="callboxfirst">
                    <h4 style={{marginBottom:"0px",fontWeight:"400"}}>Call us Today to Book an Appointment</h4>
                    {/* <h6>One of the most technologically advanced Diagnostic & Imaging Center as well as the best Endoscopy Lab in Bengaluru, Karnataka.</h6> */}
                </div>
                <div className="callboxsecond">
                    <a style={{fontWeight:"400"}} href="tel:9035122020">Call: +91 9035122020</a>
                </div>
            </div>
        </div>
    </div>
    </>)
}
export default Callusgetapp;