import LazyLoad from "react-lazy-load";
import { Link } from "react-router-dom";

function Doctor() {
  return (
    <>
      <div className="section">
        <div className="container">
          <div className="title">
            <h4>Dr. Pratibha</h4>
            <h6>Obstetrician & Gynecologist</h6>
          </div>
          <div className="onerow">
            <div className="docphoto">
              <LazyLoad>
                <img src="photo/doctor-one.jpg" alt="" />
              </LazyLoad>
            </div>
            <div style={{ textAlign: "left" }} className="">
              <div className="alikjbdkjv">
                <h6 style={{ margin: "0px", marginRight: "10px" }}>
                  Qualification:
                </h6>
                <p>
                  MBBS,  MS(OBG). Graduated from M.S. Ramaiah Medical College &
                  postgraduated from KIMS Hubli.
                </p>
              </div>
              <p style={{ marginBottom: "10px" }}>
                Dr. Prathibha is a renowned and experienced Obstetrics &
                Gynecology in Bangalore. She brings with her an experience of{" "}
                <strong>12+ years</strong> and has been associated with some of
                the best hospitals in Bangalore. A dedicated compassionate
                doctor who handles many challenging cases with the latest
                cutting edge technology. She offers patient-friendly scientific
                advice to your problems while maintaining the highest
                professional and ethical values.
              </p>
              <h6 style={{ marginBottom: "20px" }}>
                Dr. Prathibha's clinic offers these services
              </h6>
              <div style={{ display: "grid" }} className="docterservice">
                <Link
                  to="/obstetrics"
                  style={{
                    display: "block",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                  className="changediser"
                >
                  <LazyLoad
                    className="lazyloader"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        width: "60px",
                        marginBottom: "0px",
                        justifyContent: "center",
                      }}
                      src="photo/obstetrical.png"
                      alt=""
                    />
                  </LazyLoad>
                  <div
                    style={{
                      marginTop: "0px",
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h6 style={{ textAlign: "center", marginTop: "12px" }}>
                      Obstetrics
                    </h6>
                  </div>
                </Link>
                <Link
                  to="/gynaecology"
                  style={{
                    display: "block",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                  className="changediser"
                >
                  <LazyLoad
                    className="lazyloader"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        width: "60px",
                        marginBottom: "0px",
                        justifyContent: "center",
                      }}
                      src="photo/gynecology.png"
                      alt=""
                    />
                  </LazyLoad>
                  <div
                    style={{
                      marginTop: "0px",
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h6 style={{ textAlign: "center", marginTop: "12px" }}>
                      Gynaecology
                    </h6>
                  </div>
                </Link>
                <Link
                  to="/fetal"
                  style={{
                    display: "block",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                  className="changediser"
                >
                  <LazyLoad
                    className="lazyloader"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        width: "60px",
                        marginBottom: "0px",
                        justifyContent: "center",
                      }}
                      src="photo/fertilization.png"
                      alt=""
                    />
                  </LazyLoad>
                  <div
                    style={{
                      marginTop: "0px",
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h6 style={{ textAlign: "center", marginTop: "12px" }}>
                      Fertility
                    </h6>
                  </div>
                </Link>

                {/* <ul>
                            <li><img  className="whiter-img-arrow" src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt="" /><p>Normal delivery</p></li>
                            <li><img  className="whiter-img-arrow" src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt="" /><p>High risk pregnancy</p></li>
                            <li><img  className="whiter-img-arrow" src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt="" /><p>Gynae surgeries</p></li>
                            <li><img  className="whiter-img-arrow" src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt="" /><p>Fibroid treatment</p></li>
                            <li><img  className="whiter-img-arrow" src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt="" /><p>Laparoscopy Doctor provides In-Clinic</p></li>
                            <li><img  className="whiter-img-arrow" src="photo/right-arrow-angle-black-circular-interface-symbol.png" alt="" /><p>Online (Audio/ Video) Consultation</p></li>
                        </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Doctor;
